import Instance from '../../../../utils/Axios';

const EnviarValidacaoRH = (ydata, setValidarBtn) => async (dispatch) => {
  const stateToken = localStorage.token;

  if (stateToken) {
    await Instance()
      .post('/obras/validacao/recursoshumanos/direta', { ydata })
      .then(({ data }) => {
        console.log(data);
        setValidarBtn(true);
        if (stateToken) {
          dispatch({ type: 'POST_VALIDACAO_RH', payload: data });
        }
      })
      .catch(({ err }) => console.log(err));
  }
};

export default EnviarValidacaoRH;
