import Instance from '../../../../utils/Axios';

const GetAllDiasFeriasRecursos = (recursoId, yearChosen, show) => async (dispatch) => {
  if (recursoId) {
    const stateToken = localStorage.token;
    await Instance()
      .get(`/syncdiasferias/all_dias_ferias_recurso/${recursoId}/${yearChosen}`)
      .then(({ data }) => {
        console.log(data);
        if (stateToken && dispatch) {
          dispatch({
            type: 'GET_ALL_DIAS_FERIAS_RECURSO',
            payload: data.yData[0].dias,
          });
        }
      })
      .catch((err) => {
        console.log(err);
        // eslint-disable-next-line eqeqeq
        if (err.response.status != 200) {
          show('warning', 'Preencha todos os campos');
        }
      });
  }
};

export default GetAllDiasFeriasRecursos;
