import React from 'react';
import Typography from '@mui/material/Typography';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import { Link } from 'react-router-dom';
// import Link from "@mui/material/Link";

function BreadValidacaoIndividualAD() {
  return (
    <Breadcrumbs separator="›" aria-label="breadcrumb" style={{ marginBottom: '10px' }}>
      <Link to="/app" color="textPrimary">
        Inicio
      </Link>
      <Link to="/app/validacao/administracao" color="textPrimary">
        Validação Administração
      </Link>
      <Typography color="textPrimary">Validação individual</Typography>
    </Breadcrumbs>
  );
}
export default BreadValidacaoIndividualAD;
