import Instance from '../../../../utils/Axios';

const SyncFeriasAllFuncionarios = () => async (dispatch) => {
  const stateToken = localStorage.token;

  await Instance()
    .get(`/syncdiasferias/allRecursos`)
    .then(({ data }) => {
      if (stateToken && dispatch) {
        dispatch({
          type: 'GET_SYNC_FERIAS_ALL_FUNCIONARIOS',
          payload: data,
        });
      }
    })
    .catch(({ err }) => console.log(err));
};

export default SyncFeriasAllFuncionarios;
