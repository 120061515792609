import Instance from '../../../utils/Axios';

const FuncionarioCategorias = () => async (dispatch) => {
  const stateToken = localStorage.token;

  await Instance()
    .get('/recursos/categorias')
    .then(({ data }) => {
      if (stateToken && dispatch) {
        dispatch({ type: 'GET_FUNCIONARIO_CATEGORIAS', payload: data.yData });
      }
    })
    .catch(({ err }) => console.log(err));
};

export default FuncionarioCategorias;
