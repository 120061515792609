import Instance from '../../../../utils/Axios';

const GetFuncionarioAusencia = (ausenciaId) => async (dispatch) => {
  const stateToken = localStorage.token;
  await Instance()
    .get(`/recursos/ausencias/${ausenciaId}`)
    .then(({ data }) => {
      console.log(data);
      if (stateToken && dispatch) {
        dispatch({ type: 'GET_FUNCIONARIO_AUSENCIA', payload: data.yData[0] });
      }
    })
    .catch(({ err }) => console.log(err));
};

export default GetFuncionarioAusencia;
