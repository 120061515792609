/* eslint-disable default-param-last */
import { filtersInitialState } from '../InitialState/filters';

const filtersReducer = (state = filtersInitialState, { type, payload }) => {
  switch (type) {
    case 'OBRA_VALIDACAO':
      return {
        ...state,
        obraValidacao: payload,
      };
    case 'FUNCIONARIO_VALIDACAO':
      return {
        ...state,
        funcionarioValidacao: payload,
      };
    default:
      return state;
  }
};

export default filtersReducer;
