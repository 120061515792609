import Instance from '../../../utils/Axios';

const HistoricoObra = (id) => async (dispatch) => {
  const stateToken = localStorage.token;
  if (stateToken) {
    await Instance()
      .get(`/obras/registartempos/${id}/historico`)
      .then(({ data }) => {
        if (stateToken) {
          dispatch({ type: 'GET_HISTORICO_OBRAS', payload: data.yData });
        }
      })
      .catch(({ err }) => console.log(err));
  }
};

export default HistoricoObra;
