import Instance from '../../../utils/Axios';

const AdicionarUser = (ydata, show, toggle) => async (dispatch) => {
  await Instance()
    .post('/users', { ydata })
    .then(({ data }) => {
      dispatch({ type: 'POST_USER', payload: data.yData });
      show('success', 'Foi Criado um novo utilizador!');
      toggle();
    })
    .catch((err) => {
      if (err.response.status === 403) {
        show('warning', err.response.data.message);
      }
    });
};

export default AdicionarUser;
