import Instance from '../../../../utils/Axios';

const GetFeriados = () => async (dispatch) => {
  const stateToken = localStorage.token;
  await Instance()
    .get(`/syncdiasferias/allferiados`)
    .then(({ data }) => {
      if (stateToken && dispatch) {
        dispatch({
          type: 'GET_FERIADOS',
          payload: data.yData[0],
        });
      }
    })
    .catch(({ err }) => console.log(err));
};

export default GetFeriados;
