/* eslint-disable quotes */
import React from 'react';
import { Col, Container, Row } from 'reactstrap';
import { makeStyles } from '@mui/styles';
import BreadRelatorios from '../../../../App/Breadcrumbs/Relatorios/MensalDeHoras/BreadMensalDeHoras';
import CardRelatorio from './components/CardRelatorio';
import FooterLinks from '../../../../Account/Footer';

const useStyles = makeStyles({
  btnOrange: {
    color: '#E21450',
    height: '40px',
    fontWeight: 'bold',
    // borderRadius: '15px',
    backgroundColor: 'white',
    border: `1px solid #707070`,
    display: 'flex',
    alignItems: 'center',
    transition: '0.2s',
    '&:hover': {
      backgroundColor: '#ECECEC',
      border: '1px solid #707070',
      // Reset on touch devices, it doesn't add specificity
      '@media (hover: none)': {
        backgroundColor: '#F7F7F7 ',
      },
    },
  },
  btnLightGreen: {
    color: 'white',
    backgroundColor: '#E21450',
    width: '100px',
    height: '40px',
    fontWeight: 'bold',
  },
});

function RelatorioMensalDeHoras() {
  const classes = useStyles();

  return (
    <>
      <Container>
        <Row>
          <Col md={12}>
            <BreadRelatorios />
            <h4
              style={{
                fontSize: '22px',
                width: '100%',
                marginTop: 20,
                marginBottom: 20,
              }}
              className="text-underline"
            >
              Relatório
            </h4>
          </Col>
        </Row>
        <Row>
          <CardRelatorio classes={classes} />
        </Row>
      </Container>
      <div className="bottom">
        <div style={{ padding: '20px 40px' }}>
          <FooterLinks />
        </div>
      </div>
    </>
  );
}

export default RelatorioMensalDeHoras;
