import Instance from '../../../utils/Axios';

const InfoObra = (id, navigate) => async (dispatch) => {
  const stateToken = localStorage.token;

  await Instance()
    .get(`/obras/${id}`)
    .then(({ data }) => {
      if (stateToken) {
        if (data && data.yData.length == 0) {
          navigate('/app/obras');
        } else {
          dispatch({ type: 'GET_INFO_OBRAS', payload: data.yData[0] });
        }
      }
    })
    .catch((err) => {
      console.log(err);
    });
};

// Meter aqui o put para enviar o HE

export default InfoObra;
