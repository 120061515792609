/* eslint-disable dot-notation */
/* eslint-disable react/jsx-curly-brace-presence */
import React, { useEffect } from 'react';

// import clsx from 'clsx';
import { makeStyles } from '@mui/styles';
import { Autocomplete, Box, Button, Card, Drawer, TextField, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import FeatherIcon from 'feather-icons-react';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import ptLocale from 'date-fns/locale/pt';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { startOfMonth, format, startOfYear } from 'date-fns';
import { CheckBox } from '@mui/icons-material';
import { useDispatch, useSelector } from 'react-redux';

// ICONS
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import { Navigate, useNavigate } from 'react-router-dom';

const useStyles = makeStyles({
  list: {
    width: '80vw',
    borderRadius: '10px 0px 0px 10px !important',
    // background: 'red !important',
  },
  fullList: {
    width: 'auto',
  },
  paper: {
    background: '#FAFAFA',
  },
  btnGuardar: {
    color: 'white',
    width: '120px',
    height: '40px',
    // borderRadius: '15px',
    backgroundColor: '#E21450',
    border: `1px solid #E21450`,
    display: 'flex',
    alignItems: 'center',
    transition: '0.2s',
    '&:hover': {
      backgroundColor: '#bc1041',
      border: '1px solid #bc1041',
      // Reset on touch devices, it doesn't add specificity
      '@media (hover: none)': {
        backgroundColor: '#bc1041',
      },
      '&:disabled': {
        backgroundColor: '#bc1041',
      },
    },
  },
  btnCloseDrawer: {
    color: '#464255',
    width: '30px',
    height: '40px',
    borderRadius: '15px',
    backgroundColor: '#f2f2f2',
    border: `1px solid #f2f2f2`,
    display: 'flex',
    alignItems: 'center',
    transition: '0.2s',
    '&:hover': {
      backgroundColor: '#e0e0e0',
      border: '1px solid #e0e0e0',
      // Reset on touch devices, it doesn't add specificity
      '@media (hover: none)': {
        backgroundColor: '#e0e0e0',
      },
    },
  },
  drawerHeader: {
    display: 'flex',
    justifyContent: 'space-between',
  },

  noBorder: {
    border: 'none',
  },
  btnFiltrar: {
    color: '#E21450',
    width: '115px',
    height: '40px',
    fontWeight: 'bold',
    // borderRadius: '15px',
    backgroundColor: 'white',
    border: `1px solid #707070`,
    display: 'flex',
    alignItems: 'center',
    transition: '0.2s',
    '&:hover': {
      backgroundColor: '#ECECEC',
      border: '1px solid #707070',
      // Reset on touch devices, it doesn't add specificity
      '@media (hover: none)': {
        backgroundColor: '#F7F7F7 ',
      },
    },
  },
  btnTrue: {
    display: 'inline-block',
    width: '100%',
    height: 40,
    border: '1px solid lightgrey',
    borderRadius: '5px',
    background: 'lightgrey',
    '&:focus': {
      background: 'lightgrey',
    },
    '&:hover': {
      backgroundColor: 'lightgrey',
      // Reset on touch devices, it doesn't add specificity
      '@media (hover: none)': {
        backgroundColor: 'lightgrey',
      },
    },
  },
  btnFalse: {
    display: 'inline-block',
    width: '100%',
    height: 40,
    border: '1px solid lightgrey',
    borderRadius: '5px',
    background: 'white',
    '&:focus': {
      background: 'white',
    },
    '&:hover': {
      backgroundColor: 'lightgrey',
      // Reset on touch devices, it doesn't add specificity
      '@media (hover: none)': {
        backgroundColor: 'lightgrey',
      },
    },
  },
});

function SideDrawer({ state, toggleDrawer, dimensions, drawerWidth, id }) {
  const theme = useTheme();
  const classes = useStyles();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  return (
    <div>
      <Drawer
        anchor={'right'}
        open={state['right']}
        onClose={() => toggleDrawer('right', false)}
        classes={{ paper: classes.paper }}
      >
        <div className={classes.list} style={{ width: drawerWidth }}>
          <Typography>Drawer Validação #{id}</Typography>
        </div>
      </Drawer>
    </div>
  );
}

export default SideDrawer;
