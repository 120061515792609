/* eslint-disable no-unneeded-ternary */
import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import Layout from '../../Layout/index';

function ProtectedRoute({ isAllowed, redirectPath = '/', children }) {
  // console.log('isAllowed', isAllowed);s
  if (!isAllowed) {
    return <Navigate to={redirectPath} replace />;
  }

  return (
    <div>
      <Layout />
      <div className="container__wrap">{children ? children : <Outlet />} </div>
    </div>
  );
}

export default ProtectedRoute;
