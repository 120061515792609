/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable react/require-default-props */
/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Container, Card, CardBody, Col, Row } from 'reactstrap';
import { Button, Autocomplete, Box } from '@mui/material';
import TextField from '@mui/material/TextField';
import CloseIcon from 'mdi-react/CloseIcon';
import CheckIcon from 'mdi-react/CheckIcon';
import TodasAsObrasRelatorio from '../../../../../../redux/actions/RelatoriosActions/TodasAsObrasRelatorioAction';
import TodosOsFuncionariosRelatorio from '../../../../../../redux/actions/RelatoriosActions/TodosOsFuncionariosRelatorio';
// import RelatorioObraMensal from './RelatorioObraMensal';
import TabelaRelatorio from './TabelaRelatorio';
import RelatorioObra from '../../../../../../redux/actions/RelatoriosActions/RelatorioVistaGeralDeProjeto/RelatorioVistaGeralDeProjetoAction';

function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height,
  };
}

export function useWindowDimensions() {
  const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return windowDimensions;
}

function CardRelatorio({ classes }) {
  // eslint-disable-next-line no-unused-vars
  const [selectedMonth, setSelectedMonth] = useState(new Date());
  const { width } = useWindowDimensions();
  const [funcs, setFuncs] = useState();
  const [selectedObra, setSelectedObra] = useState();
  const [btnObterDados, setBtnObterDados] = useState(false);

  const dispatch = useDispatch();

  // const handleMonthChange = (date) => {
  //   setSelectedMonth(date);
  //   setBtnObterDados(false);
  //   dispatch({ type: 'GET_RELATORIO_VISTA_GERAL_DE_PROJETO', payload: [] });
  // };

  const handleChangeObra = (e, value) => {
    e.preventDefault();
    setSelectedObra(value);
    setBtnObterDados(false);
    dispatch({ type: 'GET_RELATORIO_VISTA_GERAL_DE_PROJETO', payload: [] });
  };

  const handleChangeFuncs = (e, value) => {
    setFuncs(value);
    setBtnObterDados(false);
    dispatch({ type: 'GET_RELATORIO_VISTA_GERAL_DE_PROJETO', payload: [] });
  };

  const handleSend = (e) => {
    e.preventDefault();
    dispatch(RelatorioObra(funcs, selectedObra));
    setBtnObterDados(true);
  };

  const obras = useSelector((state) => state.relatorios.todasAsObrasRelatorio);
  const funcionarios = useSelector((state) => state.relatorios.todosOsFuncionariosRelatorio);

  const fetchObras = () => {
    dispatch(TodasAsObrasRelatorio());
  };
  const fetchFuncionarios = () => {
    dispatch(TodosOsFuncionariosRelatorio());
  };

  useEffect(() => {
    fetchObras();
  }, []);
  useEffect(() => {
    setBtnObterDados(false);
  }, []);
  useEffect(() => {
    fetchFuncionarios();
  }, []);
  // useEffect(() => handleSend(), []);
  useEffect(() => {
    dispatch({ type: 'GET_RELATORIO_VISTA_GERAL_DE_PROJETO', payload: [] });
  }, []);

  return (
    <Container>
      <Row>
        <Col>
          <Card>
            <CardBody>
              <Col md={12} sm={12}>
                <Box sx={{ borderBottom: '1px solid #000', mb: 3 }}>
                  <h4
                    style={{
                      width: '100%',
                    }}
                    className="text-underline-title"
                  >
                    Vista Geral de Projeto
                  </h4>
                </Box>
                {/* <Container> */}
                <Box sx={{ display: 'flex', flexDirection: width < 1100 ? 'column' : 'row' }}>
                  {/* <Col md={4} sm={12}>
                      
                        <MuiPickersUtilsProvider utils={DateFnsUtils} locale={pt}>
                          <Grid container justify="center">
                            <DatePicker
                              maxDate={addYears(new Date(), 1)}
                              style={{ width: '100%' }}
                              margin="normal"
                              views={['month', 'year']}
                              label="Escolha o Mês"
                              okLabel="ok"
                              cancelLabel="cancelar"
                              value={selectedMonth}
                              onChange={handleMonthChange}
                            />
                          </Grid>
                        </MuiPickersUtilsProvider>
                      </ThemeProvider>
                    </Col> */}

                  <Box
                    sx={{
                      width: width < 1100 ? '100%' : '20%',
                      mr: width < 1100 ? 0 : '2%',
                      mb: width < 1100 ? 2 : 0,
                    }}
                  >
                    <b>Obra</b>
                    <Autocomplete
                      id="combo-box-demo"
                      name="obra"
                      noOptionsText="Sem dados"
                      size="small"
                      sx={{ mt: -2 }}
                      clearText="Limpar"
                      openText="Abrir"
                      closeText="Fechar"
                      options={obras || []}
                      // onInputChange={(event, value, reason) => {
                      //   if (reason == 'clear') {
                      //     setData({
                      //       ...data,
                      //       encarregado: { id: '0', tipo: 0, nome: '', nome2: '', temUtilizador: 0 },
                      //     });
                      //   }
                      // }}
                      filterOptions={(options, state) => {
                        return options.filter(
                          (option) =>
                            option.id.length > 0 &&
                            `${option.id} - ${option.descricao}`
                              .toLowerCase()
                              .includes(state.inputValue.toLowerCase()),
                        );
                      }}
                      getOptionLabel={
                        (option) => {
                          if (option && option.id) {
                            if (option.descricao && option.descricao) {
                              return `${option.id} - ${option.descricao}  `;
                            }
                          }
                          return '';
                        }
                        // option.id ? `${option.id} - ${option.nome} - ${option.nome2}` : ''
                      }
                      isOptionEqualToValue={(option, value) => {
                        return value && value.id === option.id;
                      }}
                      renderTags={(options) => {
                        return options.map((option) => (
                          <>
                            {option.aberta === 0 ? (
                              <>
                                <CloseIcon color="tomato" />
                                <p>
                                  {option.id} - {option.descricao}
                                </p>
                              </>
                            ) : (
                              <>
                                <CheckIcon color="lightgreen" />
                                <p>
                                  {option.id} - {option.descricao}
                                </p>
                              </>
                            )}
                          </>
                        ));
                      }}
                      renderOption={(props, option) => {
                        return (
                          <li {...props}>
                            {option.aberta === 0 ? (
                              <>
                                <CloseIcon color="tomato" />
                                &nbsp;
                                <span>
                                  {option.id} - {option.descricao}
                                </span>
                              </>
                            ) : (
                              <>
                                <CheckIcon color="lightgreen" />
                                &nbsp;
                                <span>
                                  {option.id} - {option.descricao}
                                </span>
                              </>
                            )}
                          </li>
                        );
                      }}
                      style={{ width: '100%' }}
                      onChange={(e, value) => handleChangeObra(e, value)}
                      renderInput={(params) => (
                        <div style={{ position: 'relative' }}>
                          {params.inputProps.value && (
                            <span
                              style={{
                                position: 'absolute',
                                transform: 'translateY(50%)',
                                marginLeft: '5px',
                                marginTop: '25px',
                              }}
                            >
                              {selectedObra && Object.keys(selectedObra).length > 0 ? (
                                <>
                                  {selectedObra.aberta === 0 ? (
                                    <CloseIcon color="tomato" />
                                  ) : (
                                    <CheckIcon color="lightgreen" />
                                  )}
                                </>
                              ) : (
                                ''
                              )}
                            </span>
                          )}
                          <TextField
                            {...params}
                            // variant="outlined"
                            inputProps={{
                              ...params.inputProps,
                              style: { paddingLeft: '32px' },
                            }}
                            margin="normal"
                          />
                        </div>
                      )}
                    />
                  </Box>
                  <Box
                    sx={{
                      width: width < 1100 ? '100%' : '20%',
                      mr: width < 1100 ? 0 : '2%',
                      mb: width < 1100 ? 2 : 0,
                    }}
                  >
                    <b>Funcionários</b>
                    <Autocomplete
                      id="funcionarios"
                      name="funcionarios"
                      noOptionsText="Sem dados"
                      size="small"
                      clearText="Limpar"
                      openText="Abrir"
                      closeText="Fechar"
                      autoComplete
                      includeInputInList
                      sx={{ mt: -2 }}
                      onChange={(e, value) => handleChangeFuncs(e, value)}
                      options={funcionarios || []}
                      getOptionLabel={(option) => `${option.id} - ${option.nome} - ${option.nome2}`}
                      // filterSelectedOptions
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          // variant="outlined"
                          margin="normal"
                        />
                      )}
                    />
                  </Box>
                  <Box
                    sx={{
                      width: width < 1100 ? '100%' : '10%',
                      mr: width < 1100 ? 0 : '2%',
                      mb: width < 1100 ? 2 : 0,
                      mt: width < 1100 ? 0 : 2.5,
                    }}
                  >
                    <Button
                      size="small"
                      // color="primary"
                      variant="contained"
                      onClick={(e) => handleSend(e)}
                      className={classes.btnOrange}
                    >
                      Filtrar
                    </Button>
                  </Box>
                </Box>

                {/* </Container> */}
              </Col>

              <TabelaRelatorio
                fdate={selectedMonth}
                btnObterDados={btnObterDados}
                setBtnObterDados={setBtnObterDados}
                classes={classes}
              />
            </CardBody>
          </Card>
        </Col>
      </Row>
    </Container>
  );
}

export default CardRelatorio;
