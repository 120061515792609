import Instance from '../../../../utils/Axios';

const RemoverFuncionarioRegistoHoras = (registoHorasId, show) => async (dispatch) => {
  await Instance()
    .delete(`/recursos/horas/${registoHorasId}`)
    .then(({ data }) => {
      dispatch({ type: 'DELETE_FUNCIONARIO_REGISTO_HORAS', payload: data });
      show('success', 'Registo de horas eliminado');
    })
    .catch(({ err }) => {
      if (err.response) {
        if (err.response.status === 500) {
          show('danger', `${err.response.data.message}`);
        }
      } else {
        show('warning', `Preencha todos os campos`);
      }
    });
};

export default RemoverFuncionarioRegistoHoras;
