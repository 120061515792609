import Instance from '../../../../utils/Axios';

const GetValidacaoAD = (datainicio, datafim, historico, obra, funcionario) => async (dispatch) => {
  console.log(datainicio, datafim, historico, obra, funcionario);
  await Instance()
    .get(`/obras/validacao/administradores`, {
      params: { datainicio, datafim, historico, obra, funcionario },
    })
    .then(({ data }) => {
      console.log(data);
      dispatch({ type: 'GET_VALIDACAO_AD', payload: data.yData });
    })
    .catch((err) => {
      console.log(err);
    });
};

export default GetValidacaoAD;
