/* eslint-disable prettier/prettier */
/* eslint-disable no-shadow */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/self-closing-comp */
/* eslint-disable import/order */
import React, { useEffect, useState } from 'react';

import { Autocomplete, Box, Button, InputAdornment, TextField } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import red from '@mui/material/colors/red';
import yellow from '@mui/material/colors/yellow';
import green from '@mui/material/colors/green';

import { getYear } from 'date-fns';

import EditarFucionario from '../../../../../../redux/actions/FuncionariosActions/EditarFuncionarioAction';
import TodosFuncionarios from '../../../../../../redux/actions/FuncionariosActions/TodosOsFucionariosAction';

import Checkboxes from './Checkboxes';

import GetDiasMarcadosEmpresa from '../../../../../../redux/actions/FuncionariosActions/syncFerias/getDiasMarcadosEmpresaAction';
import GetDiasMarcados from '../../../../../../redux/actions/FuncionariosActions/syncFerias/getDiasMarcadosAction';
import GetDiasDisponiveis from '../../../../../../redux/actions/FuncionariosActions/syncFerias/getDiasDisponiveisAction';
import GetSyncFerias from '../../../../../../redux/actions/FuncionariosActions/syncFerias/syncFeriasFuncionarioAction';
import EditarAllDiasFeriasRecursos from '../../../../../../redux/actions/FuncionariosActions/syncFerias/editarAllDiasFeriasRecursoAction';
import GetAllDiasFeriasRecursos from '../../../../../../redux/actions/FuncionariosActions/syncFerias/getAllDiasFeriasRecursoAction';

// MODALS
// import ModalDelete from './modals/modalDelete';
// import ModalRedefinirSenha from './modals/modalRedefinirSenha';
// import ModalEditarUtilizador from './modals/modalEditarUtilizador';

const useStyles = makeStyles((theme) => ({
  root: {
    '& > *': {
      margin: theme.spacing(1),
      width: '25ch',
    },
  },
  btnSenha: {
    color: theme.palette.getContrastText(green[600]),
    backgroundColor: green[400],
    '&:hover': {
      backgroundColor: green[500],
      // Reset on touch devices, it doesn't add specificity
      '@media (hover: none)': {
        backgroundColor: green[400],
      },
    },
    width: '200px',
    height: '40px',
  },
  btnApagar: {
    color: theme.palette.getContrastText(red[600]),
    backgroundColor: red[600],
    '&:hover': {
      backgroundColor: red[700],
      // Reset on touch devices, it doesn't add specificity
      '@media (hover: none)': {
        backgroundColor: red[600],
      },
    },
    width: '200px',
    height: '40px',
  },
  btnApagar1: {
    color: theme.palette.getContrastText(red[600]),
    backgroundColor: red[600],
    '&:hover': {
      backgroundColor: red[700],
      // Reset on touch devices, it doesn't add specificity
      '@media (hover: none)': {
        backgroundColor: red[600],
      },
    },
    width: '20px',
    height: '35px',
    margin: '0px 20px 0px 0px',
  },
  btnEditar: {
    color: 'white',
    backgroundColor: yellow[600],
    '&:hover': {
      backgroundColor: yellow[700],
      // Reset on touch devices, it doesn't add specificity
      '@media (hover: none)': {
        backgroundColor: yellow[600],
      },
    },
    width: '200px',
    height: '40px',
  },
  btnEditar1: {
    color: 'white',
    backgroundColor: yellow[600],
    '&:hover': {
      backgroundColor: yellow[700],
      // Reset on touch devices, it doesn't add specificity
      '@media (hover: none)': {
        backgroundColor: yellow[600],
      },
    },
    width: '20px',
    height: '35px',
    margin: '0px 10px',
  },
  miniCard: {
    boxShadow: '0 3px 10px rgb(0 0 0 / 0.15)',
    minHeight: '60px',
    marginTop: '20px',
    borderRadius: '5px !important',
  },
  miniCard_date: {
    margin: '20px 0px 0px 20px !important',
    fontSize: '14px',
  },
  miniCard_obs: {
    margin: '20px 10px 10px 0px !important',
    fontSize: '14px',
    display: 'flex',
    width: '100%',
    minHeight: '30px',
  },
  miniCard_container: {
    width: '100%',
    height: '100%',
    display: 'flex',
    justifyContent: 'space-between',
  },

  btnConsultar: {
    color: 'white',
    backgroundColor: '#E21450',
    width: '100px',
    height: '40px',
    fontWeight: 'bold',
  },
  btnFiltrar: {
    color: '#E21450',
    height: '40px',
    fontWeight: 'bold',
    // borderRadius: '15px',
    backgroundColor: 'white',
    border: `1px solid #707070`,
    display: 'flex',
    alignItems: 'center',
    transition: '0.2s',
    '&:hover': {
      backgroundColor: '#ECECEC',
      border: '1px solid #707070',
      // Reset on touch devices, it doesn't add specificity
      '@media (hover: none)': {
        backgroundColor: '#F7F7F7 ',
      },
    },
  },
}));

function CardFuncionario({
  fetchFuncionario,
  show,
  handleOpen,
  // handleClose,
  // width,
  // faltaSelecionada,
  // setFaltaSelecionada,
  // setDisableFaltou,
}) {
  const dispatch = useDispatch();
  const location = useLocation();

  const classes = useStyles();
  const id = location.pathname.split('/')[4];

  const [checkboxes, setCheckboxes] = React.useState({
    ativo: false,
    operacional: false,
  });
  const [defaultSelector, setDefaultSelector] = useState({
    id: '',
    descricao: '',
  });

  const categorias = useSelector((state) => state.funcionarios.categorias);
  const funcionario = useSelector((state) => state.funcionarios.funcionario);
  const ferias = useSelector((state) => state.ferias);
  const recursoId = useSelector((state) => state.funcionarios.funcionario.id);

  // ------ User -> Card Edit usser --------//
  const [funcionarioInfo, setFuncionarioInfo] = useState({
    id: '',
    nome: '',
    valorMensal: '',
    valorHora: '',
    horasMensais: '',
    categoria: '',
    categoriaId: '',
    categoriaAutoCompleteChange: '',
  });

  const [resumoFerias, setResumoFerias] = useState({
    diasTotais: '',
  });

  const [errors, setErrors] = useState({});
  const [errorsCategoria, setErrorsCategoria] = useState({});

  // eslint-disable-next-line no-unused-vars
  const [loading, setLoading] = useState(false);

  const year = getYear(new Date());

  const fetchFuncionarios = () => {
    dispatch(TodosFuncionarios());
  };
  const funcionarios = useSelector((state) => state.funcionarios.todosFuncionarios);

  const funcsInfo = funcionarios && funcionarios.filter((func) => func.id == id)[0];

  useEffect(() => {
    setResumoFerias({
      diasTotais: ferias.diasTotaisFerias,
    });
  }, [ferias.diasTotaisFerias]);

  const toggle = () => {
    const c = categorias && categorias.find((cat) => cat.id === funcionario.categoriaId);

    setFuncionarioInfo({
      id: funcionario.id,
      nome: funcionario.nome2,
      valorMensal: funcionario.valorMensal,
      valorHora: funcionario.valorHora,
      horasMensais: funcionario.horasMensais,
      categoria: funcionario.categoriaNome,
      categoriaId: funcionario.categoriaId,
      categoriaAutoCompleteChange: c,
    });
    setCheckboxes({
      ativo: funcionario.ativo,
      operacional: funcionario.operacional,
    });
    setDefaultSelector({
      id: funcsInfo.categoriaId ? funcsInfo.categoriaId : '',
      descricao: funcsInfo.categoriaNome ? funcsInfo.categoriaNome : '',
    });
  };

  const validateOnChange = true;

  const validation = (fieldValues = funcionario) => {
    const temp = { ...errors };
    if ('horasMensais' in fieldValues)
      temp.horasMensais = fieldValues.horasMensais ? '' : 'Campo Obrigatório';
    setErrors({
      ...temp,
    });

    if (fieldValues == funcionario) {
      return Object.values(temp).every((x) => x == '');
    }
    return false;
  };

  const handleFields = (e) => {
    e.preventDefault();
    setFuncionarioInfo({
      ...funcionario,
      [e.target.name]: e.target.value,
    });
    if (validateOnChange) validation({ [e.target.name]: e.target.value });
  };
  const handleChangeDiasTotais = (e) => {
    e.preventDefault();
    setResumoFerias({
      ...resumoFerias,
      [e.target.name]: e.target.value,
    });
  };

  const validationCategoria = (fieldValuesCategoria = defaultSelector) => {
    const temp = { ...errorsCategoria };

    if ('id' in fieldValuesCategoria) {
      temp.id = fieldValuesCategoria.id == '' ? 'Campo Obrigatório' : '';
    }

    setErrorsCategoria({
      ...temp,
    });

    if (fieldValuesCategoria == defaultSelector) {
      return Object.values(temp).every((x) => x == '');
    }
    return false;
  };

  const handleChangeCategoria = (value) => {
    console.log('value', value);
    if (value) {
      setFuncionarioInfo({
        ...funcionario,
        categoriaId: value.id,
        categoriaAutoCompleteChange: value,
      });
      setDefaultSelector({
        ...defaultSelector,
        id: value.id,
        descricao: value.descricao,
      });

      // setDefaultSelector(value);
      if (validateOnChange) validationCategoria({ id: value.id });
    } else if (value == null) {
      setDefaultSelector({
        ...defaultSelector,
        id: '',
        descricao: '',
      });
    }
  };

  const onSubmit = (e) => {
    e.preventDefault();
    const idFunc = funcionario.id;
    console.log(validation() && validationCategoria());
    const ydata = [
      {
        // Funcionario
        horasMensais: funcionarioInfo.horasMensais,
        categoriaId: defaultSelector.id,
        ativo: checkboxes.ativo ? 1 : 0,
        operacional: checkboxes.operacional ? 1 : 0,
      },
    ];
    console.log(ydata);

    if (validation()) {
      setLoading(true);
      dispatch(EditarFucionario(idFunc, ydata, show, toggle))
        // .then(() => fetchCategorias())
        .then(() => fetchFuncionarios())
        .then(() => fetchFuncionario(idFunc));
    }

    const dias = [
      {
        // Resumo Férias
        // eslint-disable-next-line radix
        diasTotais: parseInt(resumoFerias.diasTotais),
      },
    ];

    dispatch(EditarAllDiasFeriasRecursos(recursoId, year, dias, show))
      // .then(() => fetchCategorias())
      .then(() => fetchFuncionarios())
      .then(() => fetchFuncionario(idFunc));
  };

  useEffect(() => {
    if (funcionario) {
      toggle();
    }
  }, [funcionario]);

  useEffect(() => {
    if (funcionario) {
      toggle();
    }
  }, [funcionario]);

  const fetchDiasMarcadosEmpresa = () => {
    dispatch(GetDiasMarcadosEmpresa());
  };

  const fetchDiasMarcados = (recursoId) => {
    dispatch(GetDiasMarcados(recursoId));
  };

  const fetchDiasDisponiveis = (recursoId, yearChosen) => {
    dispatch(GetDiasDisponiveis(recursoId, yearChosen));
  };

  const fetchSyncFerias = (recursoId) => {
    dispatch(GetSyncFerias(recursoId));
  };

  const fetchDiasTotaisFerias = (recursoId, yearChosen) => {
    dispatch(GetAllDiasFeriasRecursos(recursoId, yearChosen));
  };

  useEffect(() => {
    fetchSyncFerias(recursoId);
  }, []);

  useEffect(() => {
    fetchDiasMarcadosEmpresa(recursoId);
    fetchDiasMarcados(recursoId);
    fetchDiasDisponiveis(recursoId, year);

    fetchDiasTotaisFerias(recursoId, year);
  }, [recursoId]);

  return (
    <>
      <Box sx={{ borderBottom: '1px solid #000', mb: 3 }}>
        <h4
          style={{
            width: '100%',
          }}
          className="text-underline-title"
        >
          Resumo de Férias - {year} :
        </h4>
      </Box>
      <Box>
        <Box sx={{ display: 'flex', mb: 3, alignContent: 'center' }}>
          <Box sx={{ display: 'flex', flexDirection: 'column', width: '23%', mr: '2%' }}>
            <b>Dias Marcados Empresa:</b>
            <TextField value={ferias.diasMarcadosEmpresa} size="small" disabled />
          </Box>
          <Box sx={{ display: 'flex', flexDirection: 'column', width: '23%', mr: '2%' }}>
            <b>Dias Restantes:</b>
            <TextField value={ferias.diasDisponiveis} size="small" disabled />
          </Box>
          <Box sx={{ display: 'flex', flexDirection: 'column', width: '23%', mr: '2%' }}>
            <b>Dias Utilizados:</b>
            <TextField value={ferias.diasMarcados} size="small" disabled />
          </Box>
          <Box sx={{ display: 'flex', alignItems: 'flex-end', width: '23%' }}>
            <div style={{ display: 'flex', flexDirection: 'column', width: '40%' }}>
              <b>Dias Totais:</b>
              <TextField
                type="number"
                variant="outlined"
                size="small"
                name="diasTotais"
                value={resumoFerias.diasTotais}
                onChange={handleChangeDiasTotais}
              />
            </div>
            <Button
              variant="contained"
              className={classes.btnFiltrar}
              onClick={(e) => handleOpen('addF', e)}
              sx={{ ml: 4 }}
            >
              Marcar Férias
            </Button>
          </Box>
        </Box>
      </Box>
      <Box sx={{ borderBottom: '1px solid #000', mb: 3 }}>
        <h4
          style={{
            width: '100%',
          }}
          className="text-underline-title"
        >
          Editar Funcionário
        </h4>
      </Box>
      <form className="material-form" onSubmit={onSubmit}>
        <Box sx={{ display: 'flex', mb: 3 }}>
          <Box sx={{ display: 'flex', flexDirection: 'column', width: '73%', mr: '2%' }}>
            <b>Nome funcionário:</b>
            <TextField
              variant="outlined"
              value={funcionario.nome2}
              onChange={handleFields}
              size="small"
              disabled
            />
          </Box>
          <Box sx={{ display: 'flex', flexDirection: 'column', width: '25%' }}>
            <b>Categoria:</b>
            <Autocomplete
              id="categoriaId"
              name="categoriaId"
              noOptionsText="Sem dados"
              clearText="Limpar"
              size="small"
              openText="Abrir"
              closeText="Fechar"
              disabled
              sx={{ mt: -2 }}
              onChange={(e, value) => handleChangeCategoria(value)}
              // value={funcionario.categoriaAutoCompleteChange || ''}
              value={defaultSelector == null ? { id: 0, descricao: '' } : defaultSelector}
              options={categorias || []}
              includeInputInList
              // getOptionLabel={(option) => `${option.id} - ${option.descricao} `}
              getOptionLabel={(option) => (option.id ? `${option.id} - ${option.descricao} ` : '')}
              isOptionEqualToValue={(option, value) => {
                return value.id === option.id;
              }}
              filterOptions={(options, state) => {
                return options.filter(
                  (option) =>
                    option.id != 0 &&
                    `${option.id} - ${option.descricao}`
                      .toLowerCase()
                      .includes(state.inputValue.toLowerCase()),
                );
              }}
              onInputChange={(event, value, reason) => {
                if (reason == 'clear') {
                  setDefaultSelector({
                    id: '',
                    descricao: '',
                  });
                }
              }}
              autoComplete
              renderInput={(params) => (
                <TextField
                  {...params}
                  placeholder="Categoria"
                  margin="normal"
                  // required
                  // {...(errorsCategoria.id && {
                  //   error: true,
                  //   helperText: errorsCategoria.id,
                  // })}
                />
              )}
            />
          </Box>
        </Box>
        <Box sx={{ display: 'flex', mb: 3 }}>
          <Box sx={{ display: 'flex', flexDirection: 'column', width: '23%', mr: '2%' }}>
            <b>Valor por hora:</b>
            <TextField
              variant="outlined"
              size="small"
              type="number"
              value={funcionario.valorHora}
              onChange={handleFields}
              InputProps={{
                endAdornment: <InputAdornment position="start">€</InputAdornment>,
              }}
              disabled
            />
          </Box>
          <Box sx={{ display: 'flex', flexDirection: 'column', width: '23%', mr: '2%' }}>
            <b>Valor Mensal:</b>
            <TextField
              variant="outlined"
              size="small"
              type="number"
              value={funcionario.valorMensal}
              onChange={handleFields}
              InputProps={{
                endAdornment: <InputAdornment position="start">€</InputAdornment>,
              }}
              disabled
            />
          </Box>
          <Box sx={{ display: 'flex', flexDirection: 'column', width: '23%', mr: '2%' }}>
            <b>Horas Mensais:</b>
            <TextField
              type="number"
              variant="outlined"
              size="small"
              name="horasMensais"
              value={funcionarioInfo.horasMensais}
              onChange={handleFields}
            />
          </Box>
          <Box sx={{ display: 'flex', width: '25%' }}>
            <Checkboxes
              checkboxes={checkboxes}
              setCheckboxes={setCheckboxes}
              funcionario={funcionarioInfo}
              funcionarioStore={funcionario}
            />
          </Box>
        </Box>
        <Button
          className={classes.btnConsultar}
          variant="contained"
          sx={{ float: 'right' }}
          type="submit"
        >
          Guardar
        </Button>
      </form>
    </>
  );
}

export default CardFuncionario;
