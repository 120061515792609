import axios from 'axios';
import BASE_URL from '../../../BASE_URL';

const DefinirSenha = (ydata, show) => async (dispatch) => {
  if (ydata[0].password === ydata[0].confirmarPassword) {
    await axios
      .post(`${BASE_URL}/signup`, { ydata })
      .then(({ data }) => {
        dispatch({ type: 'DEFINIR_SENHA', payload: data });
      })
      .then(() => show('success', 'Seja bem vindo!'))
      .catch(({ err }) => console.log(err));
  } else {
    show('danger', 'Credenciais incorretas');
  }
};

export default DefinirSenha;
