import React from 'react';
import './Tooltip.css';

// PACKAGES
import { makeStyles, withStyles } from '@mui/styles';
import { Tooltip, Zoom, IconButton } from '@mui/material';

// ICONS
import { MdInfoOutline } from 'react-icons/md';

const useStyles = makeStyles((theme) => ({
  button: {
    margin: theme.spacing(1),
    width: '40px',
  },
  customWidth: {
    maxWidth: 500,
    boxShadow: '20px 20px 20px 10px rgba(0, 0, 0, 0.8)',
    borderRadius: '14px',
  },
}));
const styles = {
  tooltip: {
    width: 'auto',
    // height: '36px',
    borderRadius: '14px',
    boxShadow: '2px 2px 2px 1px rgba(0, 0, 0, 0.2)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#464255',
    color: 'white !important',
    padding: '15px',
    fontSize: '14px',
  },
  arrow: {
    fontSize: 20,
    color: '#464255 !important',
    '&::before': {
      backgroundColor: '#464255 !important',
      // border: '2px solid red',
    },
  },
};

const CustomTooltip = withStyles(styles)(Tooltip);

function NormalTooltip({ recurso }) {
  const classes = useStyles();
  return (
    <div>
      <CustomTooltip
        arrow
        TransitionComponent={Zoom}
        title={
          <>
            {recurso && recurso.ausencia && !recurso.notificacaoHorasNoutraObra ? (
              <div>{recurso && recurso.ausencia}</div>
            ) : null}
            {recurso && recurso.notificacaoHorasNoutraObra && !recurso.ausencia ? (
              <div>{recurso && recurso.notificacaoHorasNoutraObra}</div>
            ) : null}
            {recurso && recurso.ausencia && recurso.notificacaoHorasNoutraObra ? (
              <div>
                {recurso && recurso.ausencia}
                <br />
                <br />
                {recurso && recurso.notificacaoHorasNoutraObra}
              </div>
            ) : null}
          </>
        }
        // className={classes.customWidth}
      >
        <IconButton
          aria-label="Ausência"
          className={{ arrow: classes.arrow, tooltip: classes.button }}
          size="medium"
          style={{ opacity: '1 !important', color: 'rgb(10, 207, 255)' }}
        >
          <MdInfoOutline />
        </IconButton>
      </CustomTooltip>
    </div>
  );
}
export default NormalTooltip;
