import Instance from '../../../utils/Axios';

const ObrasSemRecursos =
  (estadosProjetoPreSelectedId, estadosSubProjetoPreSelectedId) => async (dispatch) => {
    const stateToken = localStorage.token;

    const estadosProjetosId = estadosProjetoPreSelectedId;
    const estadosSubProjetosId = estadosSubProjetoPreSelectedId;

    if (stateToken) {
      await Instance()
        .get('/obras/associarobras', {
          params: {
            estadosProjetosId,
            estadosSubProjetosId,
          },
        })
        .then(({ data }) => {
          if (stateToken) {
            dispatch({ type: 'GET_OBRAS_SEM_RECURSOS', payload: data.yData });
          }
        })
        .catch((err) => console.log(err));
    }
  };

export default ObrasSemRecursos;
