import Instance from '../../../../utils/Axios';

const GetValidacaoChefeDepartamento =
  (datainicio, datafim, historico, obra, funcionario) => async (dispatch) => {
    await Instance()
      .get(`/obras/validacao/chefedepartamento`, {
        params: { datainicio, datafim, historico, obra, funcionario },
      })
      .then(({ data }) => {
        console.log(data);
        dispatch({ type: 'GET_VALIDACAO_CD', payload: data.yData });
      })
      .catch((err) => {
        console.log(err);
      });
  };

export default GetValidacaoChefeDepartamento;
