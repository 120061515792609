/* eslint-disable jsx-a11y/aria-role */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/interactive-supports-focus */
import { React, useState } from 'react';
import FooterDrawer from './components/drawer';

function FooterLinks() {
  const [drawerState, setDrawerState] = useState('');
  const [state, setState] = useState({
    right: false,
  });

  const toggleDrawer = (anchor, open, page) => {
    console.log(anchor, open, page);
    // eslint-disable-next-line eqeqeq
    if (open == false) {
      setState({ ...state, [anchor]: open });
    }
    // eslint-disable-next-line eqeqeq
    if (open == true) {
      console.log('abre o drawer');
      setDrawerState(page);
      setState({ ...state, [anchor]: open });
    }
  };

  return (
    <div>
      <FooterDrawer state={state} toggleDrawer={toggleDrawer} drawerState={drawerState} />
      <div
        style={{
          display: 'flex',
          justifyContent: 'flex-end',
        }}
      >
        <div
          className="link-div"
          onClick={() => toggleDrawer('right', true, 'condicoesUso')}
          role="link"
        >
          Condições de uso
        </div>
        <span style={{ width: '20px' }} />
        <div
          className="link-div"
          role="polPriv"
          onClick={() => toggleDrawer('right', true, 'politicasPrivacidade')}
        >
          Políticas de privacidade
        </div>
        <span style={{ width: '20px' }} />
        <div
          className="link-div"
          role="PolCookies"
          onClick={() => toggleDrawer('right', true, 'politicasCookies')}
        >
          Políticas de cookies
        </div>
      </div>
    </div>
  );
}

export default FooterLinks;
