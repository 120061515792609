import React from 'react';
import { Link } from 'react-router-dom';
// import Image404 from '../../../shared/img/404/404.png';
import './error.css';
// PACKAGES
import { ThemeProvider, createTheme, Button } from '@mui/material';
import { makeStyles } from '@mui/styles';
import orange from '@mui/material/colors/orange';

const useStyles = makeStyles(() => ({
  btnGuardar: {
    color: 'white',
    backgroundColor: '#E21450',
    '&:hover': {
      backgroundColor: '#ca164b',
    },
    width: '160px',
    height: '40px',
    marginTop: '40px',
  },
}));

const theme = createTheme({
  palette: {
    primary: { main: '#000' },
    // secondary: 'green',
    // error: 'red',
  },
});

function NotFound404() {
  const classes = useStyles();
  return (
    // <div className="not-found">
    //   <div className="not-found__content">
    //     <img className="not-found__image" src={Image404} alt="404" />
    //     <h3>404</h3>
    //     <h3 className="not-found__info">Ooops! The page you are looking for could not be found :(</h3>
    //     <Link className="btn btn-primary" to="/">
    //       Back Home
    //     </Link>
    //   </div>
    // </div>

    <ThemeProvider theme={theme}>
      <div className="home" style={{ height: '100vh' }}>
        <div className="error-back">
          {/* <img src={Logo} alt="" className="logo" /> */}
          <main
            style={{
              textAlign: 'center',
            }}
            className="mainContent"
          >
            <div>
              <div className="titulo-404">404</div>

              <div>
                <p>A página que procura não foi encontrada! Por favor volte á página de login.</p>
              </div>
              <Link to="/">
                <Button variant="contained" color="primary" className={classes.btnGuardar}>
                  Voltar ao login
                </Button>
              </Link>
            </div>
            {/* <div className="bottom-404" style={{ height: '100%' }}>
              Em caso de dúvida, contacte a administração.
            </div> */}
          </main>
        </div>
        {/* <div className='error-right'>
          <div className='center404'></div>
        </div> */}
      </div>
    </ThemeProvider>
  );
}

export default NotFound404;
