/* eslint-disable import/order */
import React, { useEffect, useState } from 'react';
import { Navigate } from 'react-router';
import withAuthFirebase from '../../../shared/components/auth/withAuthFirebase';
import LogInForm from '../../../shared/components/loginForm/LogInForm';
import './login.css';
import LoginLeft from '../../../assets/login-left.png';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    '& > *': {
      margin: theme.spacing(1),
      width: '25ch',
    },
  },
  homeLeftPT: {
    width: '60%',
    minHeight: '100vh',
    backgroundImage: 'url(https://ynb.pt/dev/jaf/login-gestao-obras-pt.png)',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: '50%',
    // eslint-disable-next-line no-dupe-keys
    backgroundSize: 'cover',
    zIndex: '10',
  },
  homeLeftFR: {
    width: '60%',
    minHeight: '100vh',
    backgroundImage: 'url(https://ynb.pt/dev/jaf/login-gestao-obras-fr.png)',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: '50%',
    // eslint-disable-next-line no-dupe-keys
    backgroundSize: 'cover',
    zIndex: '10',
  },
  homeMobilePT: {
    minHeight: '100vh',
    backgroundImage: 'url(https://ynb.pt/dev/jaf/login-gestao-obras-pt.png)',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: '50%',
    // eslint-disable-next-line no-dupe-keys
    backgroundSize: 'cover',
    zIndex: '10',
  },
  homeMobileFR: {
    minHeight: '100vh',
    backgroundImage: 'url(https://ynb.pt/dev/jaf/login-gestao-obras-fr.png)',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: '50%',
    // eslint-disable-next-line no-dupe-keys
    backgroundSize: 'cover',
    zIndex: '10',
  },
}));

// ---------------------------- Window dimensions -------------------------//

function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height,
  };
}

export function useWindowDimensions() {
  const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return windowDimensions;
}
// ---------------------------------------------------------------------//

function LogIn() {
  // const { loading } = useAuth0();
  const classes = useStyles();
  const dimensions = useWindowDimensions();

  const { token } = localStorage;

  if (token) {
    return <Navigate to="/app" />;
  }

  const classImage =
    process.env.REACT_APP_DOMAIN === 'PT' ? classes.homeMobilePT : classes.homeMobileFR;

  // if (loading) {
  //   return <Loading loading={loading} />;
  // }

  return (
    <div
      className={dimensions && dimensions.width > 800 ? 'home' : classImage}
      style={{ marginTop: dimensions && dimensions.width < 800 ? '-25vh' : '' }}
    >
      {/* <div className="home-left"> */}

      {dimensions && dimensions.width > 800 ? (
        <div
          className={
            process.env.REACT_APP_DOMAIN === 'PT' ? classes.homeLeftPT : classes.homeLeftFR
          }
        >
          <div className="left-content">
            <div className="left-content-inside">
              <img src={LoginLeft} alt="" />
            </div>
          </div>
        </div>
      ) : null}

      <LogInForm onSubmit form="log_in_form" classes={classes} dimensions={dimensions} />
    </div>
  );
}

LogIn.propTypes = {};

export default withAuthFirebase(LogIn);
