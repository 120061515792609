/* eslint-disable object-shorthand */
/* eslint-disable no-param-reassign */
/* eslint-disable no-shadow */
/* eslint-disable react/button-has-type */
/* eslint-disable react/prop-types */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from 'react';
import MUIDataTable from 'mui-datatables';
import { Col } from 'reactstrap';
import { Button } from '@mui/material';
import { useSelector } from 'react-redux';
import { format } from 'date-fns';

// PDF
import PDFGenerator from './pdfGenerator';

function TabelaRelatorio({ fdate, btnObterDados, setBtnObterDados, classes, searchText }) {
  const [responsive] = useState('standard');
  const [tableBodyHeight] = useState('100%');
  const [tableBodyMaxHeight] = useState('');

  const ydata = useSelector((state) => state.relatorios.relatorioColaboradoresAtivos.funcionarios);

  const ano = fdate.getFullYear();
  const mes = fdate.getMonth() + 1;
  const data = format(new Date(), 'dd-MM-yyyy');
  let mesAnterior = mes - 1;
  if (mesAnterior <= 0) {
    mesAnterior = 12;
  }

  const columns = [
    {
      name: 'recursos_nome2',
      label: 'Nome',
    },
    {
      name: 'obras_descricao',
      label: 'Obra',
    },
    {
      name: 'qtd',
      label: 'Horas',
    },
  ];

  const options = {
    filter: false,
    filterType: 'dropdown',
    responsive,
    search: false,
    searchText: searchText,
    tableBodyHeight,
    tableBodyMaxHeight,
    selectableRows: 'none',
    viewColumns: false,
    elevation: 0,
    pagination: true,
    download: false,
    print: false,
    textLabels: {
      body: {
        noMatch: 'Sem Registos encontrados',
        toolTip: 'Dia',
        columnHeaderTooltip: (column) => `Dia ${column.label}`,
      },
      pagination: {
        next: 'Proxima Página',
        previous: 'Página Anterior',
        rowsPerPage: 'Mostrar:',
        displayRows: 'de',
      },
    },
  };

  const handlePdf = (ydata) => (e) => {
    e.preventDefault();

    PDFGenerator(ydata, ano, mes, data);
    setBtnObterDados(false);
  };

  return (
    <Col md={12} sm={12}>
      <div style={{ textAlign: 'right' }}>
        <Button
          size="small"
          // color="primary"
          onClick={handlePdf(ydata)}
          variant="contained"
          style={{ marginTop: '2px' }}
          className={classes.btnLightGreen}
          disabled={!btnObterDados || (ydata && ydata.length <= 0) || ydata == undefined}
        >
          Gerar PDF
        </Button>
      </div>
      <MUIDataTable
        description="Nada para mostrar"
        data={ydata}
        columns={columns}
        options={options}
      />
    </Col>
  );
}

export default TabelaRelatorio;
