import axios from 'axios';
import BASE_URL from '../../BASE_URL';

export const AUTHENTICATE = 'AUTHENTICATE';
export const AUTHENTICATE_ERROR_AUTH = 'AUTHENTICATE_ERROR_AUTH';

export function auth({ name, avatar }) {
  return {
    type: AUTHENTICATE,
    user: { name, avatar },
  };
}

export function authError(error) {
  return {
    type: AUTHENTICATE_ERROR_AUTH,
    error,
  };
}

// New Actions for the app

export const SignIn = (ydata, navigate, show) => async (dispatch) => {
  await axios
    .post(`${BASE_URL}/user/login`, { ydata })
    .then(({ data }) => {
      if (data.token) {
        show('success', 'Seja bem vindo!');
        dispatch({ type: 'SIGN_IN', payload: data });
        // localStorage.setItem('token', data.token);
        localStorage.setItem('token', data.token);

        navigate('/app');
      }
    })
    .catch(() => {
      show('danger', 'Credenciais incorretas');
    });
};
