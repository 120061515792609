/* eslint-disable react/prop-types */
import React from 'react';

function CardInfoObra({ ydata, fetchInfoObra }) {
  return (
    <div>
      {ydata === undefined ? (
        fetchInfoObra()
      ) : (
        <>
          <div style={{ backgroundColor: '#ededed', padding: '1rem', borderRadius: '5px' }}>
            <h4 style={{ marginBottom: '15px' }}>
              <b> Obra</b>
            </h4>
            <h5>
              {ydata.id} - {ydata.descricao}
            </h5>
          </div>
          <hr />
          <div>
            <b>Informação da obra</b>
            <p>Morada: {ydata.obraMorada1}</p>
            <p>Cód. Postal: {ydata.obraCodigoPostal}</p>
            <p>Distrito: {ydata.obraDistrito}</p>
            <hr />
            <b>Informação do cliente</b>
            <p>Cliente: {ydata.clienteNome}</p>
            <p>Morada: {ydata.clienteMorada1}</p>
            <p>Cód.Postal: {ydata.clienteCodigoPostal}</p>
            <p>Distrito: {ydata.clienteCidade}</p>
          </div>
        </>
      )}
    </div>
  );
}

export default CardInfoObra;
