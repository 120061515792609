/* eslint-disable react/jsx-no-useless-fragment */
import React from 'react';
import { Card, CardBody, Col, Row } from 'reactstrap';
import {
  TextField,
  Button,
  Checkbox,
  InputAdornment,
  IconButton,
  Autocomplete,
  Box,
} from '@mui/material';
import { withStyles } from '@mui/styles';

import { FiSearch } from 'react-icons/fi';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';

const CustomCheckbox = withStyles({
  root: {
    color: '#929CA6',
    '&$checked': {
      color: '#929CA6',
    },
    background: 'transparent !important',
  },
  checked: {},
})((props) => <Checkbox color="default" {...props} />);

function FiltrosEscritorio({
  estadosProjeto,
  estadosSubProjeto,
  estadosProjetoPreSelectedId,
  setEstadosProjetoPreSelectedId,
  estadosSubProjetoPreSelectedId,
  setEstadosSubProjetoPreSelectedId,
  fetchObras,
  classes,
  searchText,
  setSearchText,
  width,
}) {
  const checkEstadosProjetoId = estadosProjeto && estadosProjeto.map((ep) => ep.descricao);
  const checkEstadosSubProjetoId = estadosSubProjeto && estadosSubProjeto.map((ep) => ep.descricao);

  const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
  const checkedIcon = <CheckBoxIcon fontSize="small" />;

  const handleSubmit = () => {
    const eProjetosId = estadosProjetoPreSelectedId.map((ep) => ep.id);
    const eSubProjetosId = estadosSubProjetoPreSelectedId.map((ep) => ep.id);

    fetchObras(eProjetosId, eSubProjetosId);
  };
  return (
    <Row>
      <Col>
        <Card>
          <CardBody>
            <Col md={12} sm={12}>
              <Box sx={{ display: 'flex', flexDirection: width < 1100 ? 'column' : 'row' }}>
                <Box
                  sx={{
                    width: width < 1100 ? '100%' : '28%',
                    mr: width < 1100 ? 0 : '2%',
                    mb: width < 1100 ? 3 : 0,
                  }}
                >
                  <b>Estado do Projeto</b>
                  <Autocomplete
                    multiple
                    fullWidth
                    id="estadoProjeto"
                    name="estadoProjeto"
                    clearText="Limpar"
                    openText="Abrir"
                    closeText="Fechar"
                    noOptionsText="Sem dados"
                    className="unidadesId-form"
                    limitTags={3}
                    options={estadosProjeto}
                    getOptionLabel={(option) => `${option.descricao}` || ''}
                    value={estadosProjetoPreSelectedId}
                    isOptionEqualToValue={(option, value) => {
                      return value.id === option.id;
                    }}
                    style={{ width: '100%' }}
                    onChange={(e, value) => setEstadosProjetoPreSelectedId(value)}
                    renderOption={(props, option, { selected }) => {
                      if (checkEstadosProjetoId.includes(option)) {
                        // eslint-disable-next-line no-param-reassign
                        selected = true;
                      }
                      return (
                        <li {...props}>
                          <CustomCheckbox
                            icon={icon}
                            checkedIcon={checkedIcon}
                            style={{ marginRight: 8 }}
                            checked={selected}
                          />
                          {option.descricao}
                        </li>
                      );
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        size="small"
                        InputProps={{
                          ...params.InputProps,

                          endAdornment: (
                            <React.Fragment>{params.InputProps.endAdornment}</React.Fragment>
                          ),
                        }}
                      />
                    )}
                  />
                </Box>
                <Box
                  sx={{
                    width: width < 1100 ? '100%' : '28%',
                    mr: width < 1100 ? 0 : '2%',
                    mb: width < 1100 ? 3 : 0,
                  }}
                >
                  <b>Estado do Sub-Projeto</b>
                  <Autocomplete
                    multiple
                    fullWidth
                    id="estadoSubProjeto"
                    name="estadoSubProjeto"
                    clearText="Limpar"
                    openText="Abrir"
                    closeText="Fechar"
                    noOptionsText="Sem dados"
                    className="unidadesId-form"
                    limitTags={3}
                    options={estadosSubProjeto}
                    getOptionLabel={(option) => `${option.descricao}` || ''}
                    value={estadosSubProjetoPreSelectedId}
                    isOptionEqualToValue={(option, value) => {
                      return value.id === option.id;
                    }}
                    style={{ width: '100%' }}
                    onChange={(e, value) => setEstadosSubProjetoPreSelectedId(value)}
                    renderOption={(props, option, { selected }) => {
                      if (checkEstadosSubProjetoId.includes(option)) {
                        // eslint-disable-next-line no-param-reassign
                        selected = true;
                      }
                      return (
                        <li {...props}>
                          <CustomCheckbox
                            icon={icon}
                            checkedIcon={checkedIcon}
                            style={{ marginRight: 8 }}
                            checked={selected}
                          />
                          {option.descricao}
                        </li>
                      );
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        size="small"
                        InputProps={{
                          ...params.InputProps,

                          endAdornment: (
                            <React.Fragment>{params.InputProps.endAdornment}</React.Fragment>
                          ),
                        }}
                      />
                    )}
                  />
                </Box>

                <Box
                  sx={{
                    width: width < 1100 ? '100px' : '8%',
                    mr: width < 1100 ? 0 : '2%',
                    mb: width < 1100 ? 3 : 0,
                    mt: width < 1100 ? 0 : 2.5,
                  }}
                >
                  <Button
                    variant="contained"
                    onClick={handleSubmit}
                    className={classes.btnConsultar}
                    fullWidth
                  >
                    Filtrar
                  </Button>
                </Box>
                <Box sx={{ width: width < 1100 ? '100%' : '30%', mb: width < 1100 ? 3 : 0 }}>
                  <b>Pesquisar</b>
                  <TextField
                    variant="outlined"
                    size="small"
                    fullWidth
                    name="search"
                    type="text"
                    value={searchText}
                    onChange={(e) => setSearchText(e.target.value)}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton edge="end">
                            <FiSearch />
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                </Box>
              </Box>
            </Col>
          </CardBody>
        </Card>
      </Col>
    </Row>
  );
}

export default FiltrosEscritorio;
