import React from 'react';
import { Button } from '@mui/material';

function HorasButtons({ infoValIndiv, classes, handleData }) {
  return (
    <div
      style={{
        marginLeft: '10px',
        height: '40px',
        textAlign: 'right',
        display: 'flex',
      }}
    >
      <div className={classes.horasBorder}>
        <h4>{infoValIndiv.qtd}h</h4>
      </div>
      <div style={{ marginLeft: '10px' }}>
        {infoValIndiv.qtd <= 0 ? (
          <Button color="primary" disabled size="sm" className={classes.buttons}>
            -
          </Button>
        ) : (
          <Button
            color="primary"
            size="sm"
            onClick={() => {
              handleData(infoValIndiv.qtd - 1);
            }}
            className={classes.buttons}
          >
            -
          </Button>
        )}
        {infoValIndiv.qtd >= 24 ? (
          <Button className={classes.buttons} color="primary" size="sm" disabled>
            +
          </Button>
        ) : (
          <Button
            className={classes.buttons}
            color="primary"
            size="sm"
            onClick={() => {
              handleData(infoValIndiv.qtd + 1);
            }}
          >
            +
          </Button>
        )}
      </div>
    </div>
  );
}

export default HorasButtons;
