import Instance from '../../../../../utils/Axios';

const RemoverRelatorioEscritorio = (registoId, obraId, show) => async (dispatch) => {
  const stateToken = localStorage.token;

  if (stateToken) {
    await Instance()
      .delete(`obras/registohorasescritorio/horas/${obraId}/${registoId}`)
      .then(({ data }) => {
        if (stateToken) {
          dispatch({ type: 'REMOVER_RELATORIO_ESCRITORIO', payload: data });
          show('success', 'O registo foi eliminado com sucesso!');
        }
      })
      .catch((err) => {
        // eslint-disable-next-line eqeqeq
        console.log(err);
        if (!err.response) {
          console.log('Problema de conexão.');
          show('danger', `Problema de conexão com o servidor.`);
        } else if (err.response) {
          if (err.response.status === 500) {
            show('danger', `${err.response.data.message}`);
          } else {
            console.log('4xx - Problema de conexão.');
            show('danger', `Problema de conexão com o servidor.`);
          }
        }
      });
  }
};

export default RemoverRelatorioEscritorio;
