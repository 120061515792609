/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable no-param-reassign */
/* eslint-disable no-shadow */
/* eslint-disable react/button-has-type */
/* eslint-disable react/prop-types */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { getDate, subDays } from 'date-fns';
// Datatable
import MUIDataTable from 'mui-datatables';

// eslint-disable-next-line no-unused-vars
function HistoricoPeriodo({ fdate, valorHora, mes, ano }) {
  const [responsive] = useState('standard');
  const [tableBodyHeight] = useState('100%');
  const [tableBodyMaxHeight] = useState('');

  let ydata = useSelector((state) => state.obras.historicoMensal);

  let anteAnteOntem;
  let anteOntem;
  let ontem;
  let hoje;

  if (ydata) {
    anteAnteOntem = getDate(subDays(new Date(), 3));
    anteOntem = getDate(subDays(new Date(), 2));
    ontem = getDate(subDays(new Date(), 1));
    hoje = getDate(new Date());
  }

  let mesAnterior = mes - 1;
  if (mesAnterior <= 0) {
    mesAnterior = 12;
  }

  if (ydata && ydata.length > 0) {
    ydata = ydata.map((a) => {
      a.recursos_id = a.recursos_id.toString();
      a.recursos_nome2 = a.recursos_nome2 === '' ? a.recursos_nome : a.recursos_nome2;
      a.d1 = a.d1.toString();
      a.d2 = a.d2.toString();
      a.d3 = a.d3.toString();
      a.d4 = a.d4.toString();

      a.totalHoras = a.totalHoras.toString();
      if (typeof a.total == 'number') {
        a.total = `${a.total.toFixed(2).toString()} €`;
      }

      return a;
    });
  }

  const columns = [
    {
      name: 'recursos_nome2',
      label: 'Nome',
      options: {
        filter: true,
        sort: false,
        setCellProps: () => ({ style: { minWidth: '200px', maxWidth: '300px' } }),
      },
    },
    {
      name: 'd1',
      label: anteAnteOntem,
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: 'd2',
      label: anteOntem,
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: 'd3',
      label: ontem,
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: 'd4',
      label: hoje,
      options: {
        filter: true,
        sort: false,
      },
    },

    {
      name: 'totalHoras',
      label: 'Total Horas',
      options: {
        filter: true,
        sort: false,
        setCellProps: () => ({
          style: {
            minWidth: '100px',
            maxWidth: '300px',
          },
        }),
        // eslint-disable-next-line no-unused-vars
        customBodyRender: (value, tableMeta) => {
          return <div>{value}h</div>;
        },
      },
    },
  ];

  const options = {
    filter: false,
    filterType: 'dropdown',
    responsive,
    tableBodyHeight,
    tableBodyMaxHeight,
    selectableRows: 'none',
    viewColumns: false,
    elevation: 0,
    pagination: true,
    print: false,
    download: false,
    textLabels: {
      body: {
        noMatch: 'Sem Registos encontrados',
        toolTip: 'Dia',
        columnHeaderTooltip: (column) => `Dia ${column.label}`,
      },
      pagination: {
        next: 'Proxima Página',
        previous: 'Página Anterior',
        rowsPerPage: 'Mostrar:',
        displayRows: 'de',
      },
    },
  };

  return (
    <MUIDataTable
      title="Historico"
      description="Nada para mostrar"
      data={ydata}
      columns={columns}
      options={options}
    />
  );
}

export default HistoricoPeriodo;
