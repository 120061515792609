/* eslint-disable react/jsx-no-duplicate-props */
/* eslint-disable prefer-const */
import React from 'react';
import { Col } from 'reactstrap';
import { TextField, Grid } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { subDays, getDay } from 'date-fns';

function FormDataCD({ dataInicio, dataFim, handleDataInicioChangeCD, handleDataFimChangeCD }) {
  let minDate;

  const today = new Date('2022/03/16');

  if (getDay(today) == 6) {
    minDate = subDays(today, 3);
  } else if (getDay(today) == 0) {
    minDate = subDays(today, 4);
  } else if (getDay(today) == 1) {
    minDate = subDays(today, 4);
  } else if (getDay(today) == 2) {
    minDate = subDays(today, 4);
  } else {
    minDate = subDays(today, 2);
  }

  console.log(minDate);

  return (
    <>
      <Col md={3} sm={6}>
        <Grid container justify="center">
          <DatePicker
            style={{ width: '100%' }}
            margin="normal"
            id="date-picker-dialog"
            label="Data Inicio"
            format="dd/MM/yyyy"
            okLabel="ok"
            cancelLabel="cancelar"
            invalidDateMessage="Data Inválida"
            value={dataInicio}
            onChange={handleDataInicioChangeCD}
            renderInput={(params) => (
              <TextField
                {...params}
                inputProps={{
                  ...params.inputProps,
                  placeholder: 'dd/mm/aaaa',
                }}
                InputProps={{
                  ...params.InputProps,
                  disableUnderline: true,
                }}
                variant="outlined"
                size="small"
              />
            )}
            minDate={minDate}
            maxDate={dataFim}
            shouldDisableDate={(date) => getDay(date) == 0 || getDay(date) == 6}
          />
        </Grid>
      </Col>
      <Col md={3} sm={6}>
        <Grid container justify="center">
          <DatePicker
            style={{ width: '100%' }}
            margin="normal"
            id="date-picker-dialog"
            label="Data Fim"
            format="dd/MM/yyyy"
            okLabel="ok"
            cancelLabel="cancelar"
            invalidDateMessage="Data Inválida"
            value={dataFim}
            onChange={handleDataFimChangeCD}
            KeyboardButtonProps={{
              'aria-label': 'change date',
            }}
            minDate={minDate}
            maxDate={dataFim}
            shouldDisableDate={(date) => getDay(date) == 0 || getDay(date) == 6}
          />
        </Grid>
      </Col>
    </>
  );
}

export default FormDataCD;
