import Instance from '../../../../utils/Axios';

const AdicionarAusenciasFuncionarios = (ydata, show) => async (dispatch) => {
  await Instance()
    .post('/recursos/ausencias/ ', { ydata })
    .then(({ data }) => {
      dispatch({ type: 'POST_AUSENCIAS_FUNCIONARIOS', payload: data });
      show('success', 'Foram associados ausencias ao funcionário');
    })
    .catch((err) => {
      console.log(err.response);
      if (err.response) {
        if (err.response.status === 500) {
          show('danger', `${err.response.data.message}`);
        }
      } else {
        show('warning', `Não poderam ser adicionadas ausencias`);
      }
    });
};

export default AdicionarAusenciasFuncionarios;
