import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';

// ICONS
import { FiUsers, FiCheckSquare, FiFile, FiTool, FiLogOut, FiDatabase } from 'react-icons/fi';
import { AiOutlineClockCircle } from 'react-icons/ai';
import { RiUserSettingsLine } from 'react-icons/ri';

import { makeStyles } from '@mui/styles';

// SIDEBAR
import SidebarLink from './SidebarLink';
import SidebarCategory from './SidebarCategory';

const useStyles = makeStyles({
  sidebarIcon: {
    minWidth: '20px',
  },
});

function SidebarContent({ onClick, sidebarCollapse }) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const hideSidebar = (type) => {
    onClick();
    dispatch({ type, payload: 0 });
  };
  const hideSearch = (type) => {
    onClick();
    dispatch({ type, payload: '' });
  };

  const [databaseName, setDatabaseName] = useState('');

  useEffect(() => {
    fetch(`${process.env.REACT_APP_API_URL}/database-info`)
      .then((response) => response.json())
      .then((data) => setDatabaseName(data.databaseName))
      .catch((error) => console.error('Error fetching database name:', error));
  }, []);

  const logout = () => {
    localStorage.removeItem('token');
    localStorage.clear('persist:root');
  };

  const permissoes = useSelector((state) => state.user.permissoes);

  return (
    <div className="sidebar__content">
      <ul className="sidebar__block">
        {permissoes && permissoes.includes('CRIAR_EQUIPAS') ? (
          <SidebarLink
            title="Criar Equipas"
            icon={<FiUsers size={20} className={classes.sidebarIcon} />}
            route="/app/criarEquipas"
            onClick={() => {
              hideSidebar('CRIAR_EQUIPAS_TABLE_PAGINATION');
              hideSearch('CRIAR_EQUIPAS_TABLE_SEARCH');
            }}
          />
        ) : null}

        {permissoes && !permissoes.includes('REGISTAR_HORAS_ESCRITORIO') ? (
          <SidebarLink
            title="Registar Horas"
            icon={<AiOutlineClockCircle size={20} />}
            route="/app/registarHoras/operacionais"
            onClick={() => {
              hideSidebar('REG_HORAS_OPERACIONAIS_TABLE_PAGINATION');
              hideSearch('REG_HORAS_OPERACIONAIS_TABLE_SEARCH');
            }}
          />
        ) : null}
        {permissoes && permissoes.includes('REGISTAR_HORAS_ESCRITORIO') ? (
          <SidebarCategory
            title="Registar Horas"
            icon={<AiOutlineClockCircle size={20} className={classes.sidebarIcon} />}
            sidebarCollapse={sidebarCollapse}
          >
            {permissoes && permissoes.includes('REGISTAR_HORAS_OPERACIONAIS') ? (
              <SidebarLink
                title="Operacionais"
                route="/app/registarHoras/operacionais"
                onClick={() => {
                  hideSidebar('REG_HORAS_OPERACIONAIS_TABLE_PAGINATION');
                  hideSearch('REG_HORAS_OPERACIONAIS_TABLE_SEARCH');
                }}
              />
            ) : null}
            {permissoes && permissoes.includes('REGISTAR_HORAS_ESCRITORIO') ? (
              <SidebarLink
                title="Escritório"
                route="/app/registarHoras/escritorio"
                onClick={() => {
                  hideSidebar('REG_HORAS_ESCRITORIO_TABLE_PAGINATION');
                  hideSearch('REG_HORAS_ESCRITORIO_TABLE_SEARCH');
                }}
              />
            ) : null}
          </SidebarCategory>
        ) : null}

        {permissoes && permissoes.includes('OBRAS') ? (
          <SidebarLink
            title="Obras"
            icon={<FiTool size={20} className={classes.sidebarIcon} />}
            route="/app/obras"
            onClick={() => {
              hideSidebar('OBRAS_TABLE_PAGINATION');
              hideSearch('OBRAS_TABLE_SEARCH');
            }}
          />
        ) : null}

        {permissoes && permissoes.includes('VALIDACAO_CHEFE_DEPART') ? (
          <SidebarLink
            title="Validação Chefe Depart."
            icon={<FiCheckSquare size={20} className={classes.sidebarIcon} />}
            route="/app/validacao/chefedepartamento"
            onClick={() => {
              hideSidebar('VAL_CD_TABLE_PAGINATION');
              hideSearch('VAL_CD_TABLE_SEARCH');
            }}
          />
        ) : null}

        {permissoes && permissoes.includes('VALIDACAO_REC_HUMANOS') ? (
          <SidebarLink
            title="Validação Rec. Humanos"
            icon={<FiCheckSquare size={20} className={classes.sidebarIcon} />}
            route="/app/validacao/recursoshumanos"
            onClick={() => {
              hideSidebar('VAL_RH_TABLE_PAGINATION');
              hideSearch('VAL_RH_TABLE_SEARCH');
            }}
          />
        ) : null}

        {permissoes && permissoes.includes('VALIDACAO_ADMINISTRACAO') ? (
          <SidebarLink
            title="Validação Administração"
            icon={<FiCheckSquare size={20} className={classes.sidebarIcon} />}
            route="/app/validacao/administracao"
            onClick={() => {
              hideSidebar('VAL_AD_TABLE_PAGINATION');
              hideSearch('VAL_AD_TABLE_SEARCH');
            }}
          />
        ) : null}

        {/* {permissoes && permissoes.includes('RELATORIOS') ? (
          <SidebarLink
            icon="file-empty"
            title="Relatórios"
            route="/app/relatorios"
            onClick={hideSidebar}
          />
        ) : null} */}

        {(permissoes && permissoes.includes('RELATORIOS_MENSAL_HORAS')) ||
        (permissoes && permissoes.includes('RELATORIOS_COLABORADORES_ATIVOS')) ||
        (permissoes && permissoes.includes('RELATORIOS_VISTA_GERAL_PROJETO')) ? (
          <SidebarCategory
            title="Relatórios"
            icon={<FiFile size={20} className={classes.sidebarIcon} />}
            sidebarCollapse={sidebarCollapse}
          >
            {permissoes && permissoes.includes('RELATORIOS_MENSAL_HORAS') ? (
              <SidebarLink
                title="Mensal de Horas"
                route="/app/relatorios/mensal-de-horas"
                onClick={hideSidebar}
              />
            ) : null}
            {permissoes && permissoes.includes('RELATORIOS_COLABORADORES_ATIVOS') ? (
              <SidebarLink
                title="Colaboradores Ativos"
                route="/app/relatorios/colaboradores-ativos"
                onClick={hideSidebar}
              />
            ) : null}
            {permissoes && permissoes.includes('RELATORIOS_VISTA_GERAL_PROJETO') ? (
              <SidebarLink
                title="Vista Geral de Projeto"
                route="/app/relatorios/vista-geral-de-projeto"
                onClick={hideSidebar}
              />
            ) : null}
          </SidebarCategory>
        ) : null}
      </ul>

      {permissoes &&
      (permissoes.includes('UTILIZADORES') ||
        permissoes.includes('FUNCIONARIOS') ||
        permissoes.includes('TIPOS_PERIODOS') ||
        permissoes.includes('TIPOS_FALTAS') ||
        permissoes.includes('TIPOS_ATRASOS')) ? (
        <ul className="sidebar__block">
          <SidebarCategory
            title="Administração"
            icon={<RiUserSettingsLine size={20} className={classes.sidebarIcon} />}
            sidebarCollapse={sidebarCollapse}
          >
            {permissoes && permissoes.includes('UTILIZADORES') ? (
              <SidebarLink
                title="Utilizadores"
                route="/app/administracao/utilizadores"
                onClick={() => {
                  hideSidebar('UTILIZADORES_TABLE_PAGINATION');
                  hideSearch('UTILIZADORES_TABLE_SEARCH');
                }}
              />
            ) : null}
            {permissoes && permissoes.includes('FUNCIONARIOS') ? (
              <SidebarLink
                title="Funcionários"
                route="/app/administracao/funcionarios"
                onClick={() => {
                  hideSidebar('FUNCIONARIOS_TABLE_PAGINATION');
                  hideSearch('FUNCIONARIOS_TABLE_SEARCH');
                }}
              />
            ) : null}
            {permissoes && permissoes.includes('TIPOS_PERIODOS') ? (
              <SidebarLink
                title="Tipos de Períodos"
                route="/app/administracao/tiposperiodos"
                onClick={hideSidebar}
              />
            ) : null}
            {permissoes && permissoes.includes('TIPOS_FALTAS') ? (
              <SidebarLink
                title="Tipos de Faltas"
                route="/app/administracao/tiposfaltas"
                onClick={hideSidebar}
              />
            ) : null}
            {permissoes && permissoes.includes('TIPOS_ATRASOS') ? (
              <SidebarLink
                title="Tipos de Atrasos"
                route="/app/administracao/tiposatrasos"
                onClick={hideSidebar}
              />
            ) : null}
            {/* {permissoes && permissoes.includes('LOGS_HORAS') ? (
              <SidebarLink
                title="Logs Horas"
                route="/app/administracao/logshoras"
                onClick={hideSidebar}
              />
            ) : null} */}
          </SidebarCategory>
        </ul>
      ) : null}

      <ul className="sidebar__block">
        <li className="sidebar__link">
          <FiDatabase size={20} className={classes.sidebarIcon} color="#646777" />
          <p className="sidebar__link-title" style={{ color: '#646777' }}>
            {databaseName ? `BD: ${databaseName}` : 'Loading...'}
          </p>
        </li>
        <SidebarLink
          title="Sair"
          icon={<FiLogOut size={20} className={classes.sidebarIcon} />}
          route="/login"
          onClick={logout}
        />
      </ul>
    </div>
  );
}

SidebarContent.propTypes = {
  onClick: PropTypes.func.isRequired,
  sidebarCollapse: PropTypes.bool,
};

SidebarContent.defaultProps = {
  sidebarCollapse: false,
};

export default SidebarContent;
