import Instance from '../../../utils/Axios';

const FuncionariosNaoAssociados = (id) => async (dispatch) => {
  const stateToken = localStorage.token;
  if (stateToken) {
    await Instance()
      .get(`/recursos/funcionarios/${id}`)
      .then(({ data }) => {
        if (stateToken) {
          dispatch({
            type: 'GET_FUNCIONARIOS_NAO_ASSOCIADOS',
            payload: data.yData,
          });
        }
      })
      .catch(({ err }) => console.log(err));
  }
};

export default FuncionariosNaoAssociados;
