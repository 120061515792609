/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable react/prop-types */
/* eslint-disable react/no-children-prop */
import React, { useState } from 'react';

import { useSelector } from 'react-redux';
import { TextField, FormHelperText, Autocomplete, OutlinedInput } from '@mui/material';
import { makeStyles } from '@mui/styles';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import FormControl from '@mui/material/FormControl';
// password textfield
// import { makeStyles } from '@mui/material/styles';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import green from '@mui/material/colors/green';
import red from '@mui/material/colors/red';

const useStyles = makeStyles((theme) => ({
  root: {
    // display: 'flex',
    // flexWrap: 'wrap',
    // background: 'pink',
  },
  margin: {
    margin: theme.spacing(1),
  },
  withoutLabel: {
    marginTop: theme.spacing(3),
  },
  textField: {
    width: '25ch',
  },
  btnConfirmar: {
    width: '48%',
    height: '50px',
    color: theme.palette.getContrastText(green[600]),
    backgroundColor: green[400],
    '&:hover': {
      backgroundColor: green[500],
      // Reset on touch devices, it doesn't add specificity
      '@media (hover: none)': {
        backgroundColor: green[400],
      },
    },
  },
  btnCancelar: {
    width: '48%',
    color: theme.palette.getContrastText(red[500]),
    backgroundColor: red[500],
    '&:hover': {
      backgroundColor: red[700],
      // Reset on touch devices, it doesn't add specificity
      '@media (hover: none)': {
        backgroundColor: red[500],
      },
    },
  },
  modalBtns: {
    display: 'flex',
    flexWrap: 'wrap',
    width: '100%',
    justifyContent: 'space-evenly',
    marginTop: '40px',
  },
}));

function Form({
  handleFields,
  user,
  onSubmit,
  setUser,
  // toggle,
  errors = null,
  validateOnChange = true,
  validation,
  validationPassword,
}) {
  const classes = useStyles();
  const [values, setValues] = useState({
    amount: '',
    password: '',
    weight: '',
    weightRange: '',
    showPassword: false,
  });
  const [valuesConfirmar, setValuesConfirmar] = useState({
    amount: '',
    confirmarPassword: '',
    weight: '',
    weightRange: '',
    showPassword: false,
  });
  const recursos = useSelector((state) => state.users.funcionarios);
  const perfis = useSelector((state) => state.users.perfis);

  const handleChangePassword = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.value });
    setUser({ ...user, [prop]: event.target.value });

    if (validateOnChange) validationPassword({ [event.target.name]: event.target.value });
  };
  const handleChangeConfirmarPassword = (prop) => (event) => {
    setValuesConfirmar({ ...valuesConfirmar, [prop]: event.target.value });

    setUser({ ...user, [prop]: event.target.value });

    if (validateOnChange) validationPassword({ [event.target.name]: event.target.value });
  };
  const handleClickShowPassword = () => {
    setValues({ ...values, showPassword: !values.showPassword });
  };
  const handleClickShowConfirmarPassword = () => {
    setValuesConfirmar({ ...valuesConfirmar, showPassword: !valuesConfirmar.showPassword });
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handleChangeRecursos = (e, value) => {
    if (value) {
      setUser({
        ...user,
        recursosId: value.id,
      });
      if (validateOnChange) validation({ recursosId: value.id });
    } else {
      setUser({
        ...user,
        recursosId: '',
      });
      if (validateOnChange) validation({ recursosId: '' });
    }
  };

  const handle = (e) => {
    setUser({
      ...user,
      perfisId: e.target.value,
    });
    if (validateOnChange) validation({ [e.target.name]: e.target.value });
  };

  return (
    <form onSubmit={onSubmit} style={{ height: '35vh' }}>
      <div className={classes.root}>
        <div>
          <b>Nome Completo</b>
          <TextField
            id="NomeCompleto"
            name="nome"
            color="primary"
            size="small"
            value={user.nome}
            onChange={handleFields}
            fullWidth
            autoComplete="off"
            style={{ marginBottom: 8 }}
            {...(errors.nome && { error: true, helperText: errors.nome })}
          />
        </div>
        <div>
          <b>Nome de Utilizador</b>
          <TextField
            id="Username"
            name="username"
            color="primary"
            size="small"
            value={user.username}
            onChange={handleFields}
            fullWidth
            autoComplete="off"
            style={{ marginBottom: 8 }}
            {...(errors.username && { error: true, helperText: errors.username })}
          />
        </div>

        <div>
          <FormControl
            fullWidth
            {...(errors.password && {
              error: true,
              // , helperText: errors.password
            })}
          >
            <b>Palavra passe</b>
            <OutlinedInput
              id="standard-adornment-password"
              name="password"
              // label="Palavra-passe"
              size="small"
              sx={{ mb: 1 }}
              type={values.showPassword ? 'text' : 'password'}
              value={user.password}
              // onChange={handleChangePassword('password')}
              onChange={handleChangePassword('password')}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                  >
                    {values.showPassword ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              }
            />
            {errors.password && (
              <FormHelperText style={{ color: 'red' }}>{errors.password}</FormHelperText>
            )}
          </FormControl>
        </div>
        <div>
          <FormControl
            fullWidth
            {...(errors.confirmarPassword && {
              error: true,
              // , helperText: errors.password
            })}
          >
            <b>Confirmar palavra passe</b>
            <OutlinedInput
              id="standard-adornment-password"
              name="confirmarPassword"
              label="Palavra-passe"
              size="small"
              type={valuesConfirmar.showPassword ? 'text' : 'password'}
              value={user.confirmarPassword}
              // onChange={handleChangePassword('password')}
              onChange={handleChangeConfirmarPassword('confirmarPassword')}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowConfirmarPassword}
                    onMouseDown={handleMouseDownPassword}
                  >
                    {valuesConfirmar.showPassword ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              }
            />
            {errors.confirmarPassword && (
              <FormHelperText style={{ color: 'red' }}>{errors.confirmarPassword}</FormHelperText>
            )}
          </FormControl>
        </div>
        <div>
          <b>Funcionário</b>
          <Autocomplete
            name="recursosId"
            noOptionsText="Sem dados"
            clearText="Limpar"
            openText="Abrir"
            size="small"
            closeText="Fechar"
            onChange={(e, value) => handleChangeRecursos(e, value)}
            options={recursos}
            value={recursos && recursos.find((func) => func.id == user.recursosId)}
            getOptionLabel={(option) =>
              `${option.recursos_id || option.id} - ${option.nome} - ${option.nome2}`
            }
            id="auto-complete"
            autoComplete
            includeInputInList
            renderInput={(params) => (
              <TextField
                {...params}
                {...(errors.recursosId && { error: true, helperText: errors.recursosId })}
              />
            )}
          />
        </div>
        <div>
          <FormControl fullWidth {...(errors.perfisId && { error: true })}>
            <b>Perfil</b>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={user.perfisId}
              onChange={handle}
              size="small"
              name="perfisId"
            >
              {perfis.map((perfil) => (
                <MenuItem value={perfil.id}>{perfil.descricao}</MenuItem>
              ))}
            </Select>
            {errors.perfisId && (
              <FormHelperText style={{ color: 'red' }}>{errors.perfisId}</FormHelperText>
            )}
          </FormControl>
        </div>
      </div>
    </form>
  );
}

export default Form;
