/* eslint-disable react/no-array-index-key */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/prop-types */
import React, { useEffect, Fragment } from 'react';
import { Card, CardBody, Col } from 'reactstrap';
import { Box } from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';
// import RecursosForm from "./RecursosForm";
import { useDispatch, useSelector } from 'react-redux';
import TemposForm from './TemposForm';
import CardInfoObraTempos from './Recursos/CardInfoObraTempos';
import InfoObraTempo from '../../../../../../redux/actions/TemposActions/InfoObraAction';
import ListaHistorico from './Historico/Lista';
import FuncionariosNaoAssociados from '../../../../../../redux/actions/TemposActions/FuncionariosNaoAssociadosAction';

function CardTempos({ historico, fetchHistorico, classes }) {
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const id = location.pathname.split('/')[4];
  const fetchInfoObra = () => {
    dispatch(InfoObraTempo(id, navigate));
  };
  const fetchFuncNaoAss = () => {
    dispatch(FuncionariosNaoAssociados(id));
  };

  const ydata = useSelector((state) => state.tempos.infoObra);

  useEffect(() => {
    fetchInfoObra();
  }, []);
  useEffect(() => {
    fetchFuncNaoAss();
  }, []);

  return (
    <>
      <Card>
        <CardBody>
          <CardInfoObraTempos ydata={ydata} />
        </CardBody>
      </Card>

      <Card>
        <CardBody>
          <div className="react-table__wrapper">
            <div />
          </div>
          <TemposForm
            fetchHistorico={fetchHistorico}
            fetchFuncNaoAss={fetchFuncNaoAss}
            classes={classes}
          />
        </CardBody>
      </Card>

      {historico === undefined ? (
        fetchHistorico()
      ) : (
        <Card>
          <CardBody>
            <Col md={12} sm={12}>
              <Box sx={{ borderBottom: '1px solid #000', mb: 3 }}>
                <h4
                  style={{
                    width: '100%',
                  }}
                  className="text-underline-title"
                >
                  Histórico
                </h4>
              </Box>
            </Col>
            {historico.map((registos, index) => (
              <Fragment key={index}>
                <ListaHistorico registos={registos} fetchHistorico={fetchHistorico} />
              </Fragment>
            ))}
          </CardBody>
        </Card>
      )}
    </>
  );
}

CardTempos.propTypes = {};

export default CardTempos;
