import Instance from '../../../utils/Axios';

const TodosOsRecursos = (id, navigate) => async (dispatch) => {
  const stateToken = localStorage.token;
  if (stateToken) {
    await Instance()
      .get(`/obras/associarobras/${id}`)
      .then(({ data }) => {
        if (stateToken) {
          if (data && data.yData.length == 0) {
            navigate('/app/criarEquipas');
          } else {
            dispatch({ type: 'GET_RECURSOS', payload: data.yData[0] });
          }
        }
      })
      .catch(({ err }) => console.log(err));
  }
};

export default TodosOsRecursos;
