import Instance from '../../../utils/Axios';

const DeleteRegistoHoras = (id) => async (dispatch) => {
  await Instance()
    .put(`/obras/validacao/${id}`)
    .then(({ data }) => {
      console.log(data);
      dispatch({ type: 'DELETE_REGISTO_HORAS', payload: data.yData[0] });
    })
    .catch((err) => {
      console.log(err);
    });
};

export default DeleteRegistoHoras;
