/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
// import '../parceiros.css';

// PACKAGES
import { useDispatch } from 'react-redux';
import { makeStyles } from '@mui/styles';
import { Modal, Backdrop, Fade, Button, TextField, CircularProgress, Box } from '@mui/material';

import { Col, Row, Container } from 'reactstrap';
import { grey } from '@mui/material/colors';

// ACTIONS

import EditarFeriasFuncionario from '../../../../../../../redux/actions/FuncionariosActions/ferias/editarFeriasAction';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 500,
  bgcolor: 'background.paper',
  border: 'none',
  borderRadius: 2,
  boxShadow: 3,
  p: 4,
  pt: 3,
};

const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    // border: '2px solid #000',
    borderRadius: '10px',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(3, 6, 5),
    maxWidth: '600px',
    width: '600px',
    margin: 15,
  },
  btnConfirmar: {
    color: 'white',
    backgroundColor: '#E21450',
    width: '100px',
    height: '40px',
    fontWeight: 'bold',
  },
  btnConfirmarLoading: {
    width: '48%',
    height: '50px',
    backgroundColor: grey[300],
  },
  btnCancelar: {
    color: '#E21450',
    width: '100px',
    height: '40px',
    fontWeight: 'bold',
    // borderRadius: '15px',
    backgroundColor: 'white',
    border: `1px solid #707070`,
    display: 'flex',
    alignItems: 'center',
    transition: '0.2s',
    '&:hover': {
      backgroundColor: '#ECECEC',
      border: '1px solid #707070',
      // Reset on touch devices, it doesn't add specificity
      '@media (hover: none)': {
        backgroundColor: '#F7F7F7 ',
      },
    },
  },
  modalBtns: {
    display: 'flex',
    flexWrap: 'wrap',
    width: '100%',
    justifyContent: 'space-evenly',
  },
}));

function ModalEdit({
  openEdit,
  handleClose,
  validateOnChange = true,
  errorsFerias = null,
  funcId,
  show,
  formFerias,
  setFormFerias,
  feriasId,
  fetchFuncionarioFerias,
  validationFerias,
  // Loading
  loading,
  setLoading,
}) {
  const classes = useStyles();

  const dispatch = useDispatch();

  const handleChangeFerias = (e) => {
    setFormFerias({ ...formFerias, [e.target.name]: e.target.value });
    if (validateOnChange) validationFerias({ [e.target.name]: e.target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (validationFerias()) {
      setLoading(true);
      const ydata = [
        {
          funcionarioId: funcId,
          ano: formFerias.ano,
          diasFeriasTotal: formFerias.diasFeriasTotal,
          diasFeriasManual: formFerias.diasFeriasManual,
        },
      ];

      console.log(ydata);

      dispatch(EditarFeriasFuncionario(ydata, feriasId, funcId, show))
        .then(() => handleClose('edit', e))
        .then(() => fetchFuncionarioFerias());
    }
    console.log('num dá');
  };

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      className={classes.modal}
      open={openEdit}
      onClose={(e) => handleClose('edit', e)}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={openEdit}>
        <Box sx={style}>
          <div className="header-modal">
            <h3 id="transition-modal-title" className="text-underline-title-dark">
              Editar Férias
            </h3>
            <p id="transition-modal-description">Pode editar aqui as férias do funcionário.</p>
          </div>

          <Container style={{ padding: '0' }}>
            <Row>
              <Col style={{ display: 'flex', justifyContent: 'space-between', flexWrap: 'wrap' }}>
                <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                  <b>Ano</b>
                  <TextField
                    id="ano"
                    name="ano"
                    size="small"
                    color="primary"
                    value={formFerias && formFerias.ano}
                    onChange={handleChangeFerias}
                    fullWidth
                    autoComplete="off"
                    style={{ marginBottom: 8, width: '130px' }}
                    {...(errorsFerias.ano && {
                      error: true,
                      helperText: errorsFerias.ano,
                    })}
                  />
                </Box>
                <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                  <b>Total de férias</b>
                  <TextField
                    id="diasFeriasTotal"
                    name="diasFeriasTotal"
                    size="small"
                    color="primary"
                    value={formFerias && formFerias.diasFeriasTotal}
                    onChange={handleChangeFerias}
                    fullWidth
                    type="number"
                    autoComplete="off"
                    style={{ marginBottom: 8, width: '130px' }}
                    {...(errorsFerias.diasFeriasTotal && {
                      error: true,
                      helperText: errorsFerias.diasFeriasTotal,
                    })}
                  />
                </Box>
                <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                  <b>Férias utilizadas</b>
                  <TextField
                    id="diasFeriasManual"
                    name="diasFeriasManual"
                    size="small"
                    color="primary"
                    value={formFerias && formFerias.diasFeriasManual}
                    onChange={handleChangeFerias}
                    fullWidth
                    autoComplete="off"
                    style={{ marginBottom: 8, width: '130px' }}
                    {...(errorsFerias.diasFeriasManual && {
                      error: true,
                      helperText: errorsFerias.diasFeriasManual,
                    })}
                  />
                </Box>
              </Col>
            </Row>
          </Container>
          <br />
          <br />
          <div className={classes.modalBtns}>
            <Button
              className={classes.btnCancelar}
              variant="contained"
              onClick={(e) => handleClose('edit', e)}
            >
              Cancelar
            </Button>
            <Button
              className={loading ? classes.btnConfirmarLoading : classes.btnConfirmar}
              disabled={!!loading}
              variant="contained"
              onClick={handleSubmit}
            >
              {loading && (
                <CircularProgress size={14} style={{ color: '#9d9d9d', marginRight: '10px' }} />
              )}
              Confirmar
            </Button>
          </div>
        </Box>
      </Fade>
    </Modal>
  );
}

export default ModalEdit;
