import Instance from '../../../../../utils/Axios';

const EditarRegistoEscritorio = (ydata, id, obraId, show) => async (dispatch) => {
  await Instance()
    .put(`obras/registohorasescritorio/horas/${obraId}/${id}`, { ydata })
    .then(({ data }) => {
      dispatch({ type: 'PUT_REGISTO_ESCRITORIO', payload: data });
      show('success', 'O registo foi editado com sucesso!');
    })
    .catch((err) => {
      // eslint-disable-next-line eqeqeq
      console.log(err);
      if (!err.response) {
        console.log('Problema de conexão.');
        show('danger', `Problema de conexão com o servidor.`);
      } else if (err.response) {
        if (err.response.status === 500) {
          show('danger', `${err.response.data.message}`);
        } else {
          console.log('4xx - Problema de conexão.');
          show('danger', `Problema de conexão com o servidor.`);
        }
      }
    });
};

export default EditarRegistoEscritorio;
