import Instance from '../../../../utils/Axios';

const GetValidacaoRH = (datainicio, datafim, historico, obra, funcionario) => async (dispatch) => {
  await Instance()
    .get(`/obras/validacao/recursoshumanos`, {
      params: { datainicio, datafim, historico, obra, funcionario },
    })
    .then(({ data }) => {
      console.log(data);
      dispatch({ type: 'GET_VALIDACAO_RH', payload: data.yData });
    })
    .catch((err) => {
      console.log(err);
    });
};

export default GetValidacaoRH;
