import Instance from '../../../../utils/Axios';

const EditarFuncionarioRegistoHoras = (ydata, registoHorasId, show) => async (dispatch) => {
  await Instance()
    .put(`/recursos/horas/${registoHorasId}`, { ydata })
    .then(({ data }) => {
      dispatch({ type: 'PUT_FUNCIONARIO_REGISTO_HORAS', payload: data });
      show('success', 'O registo de horas selecionado, foi editado');
    })
    .catch((err) => {
      // eslint-disable-next-line eqeqeq
      if (err.response.status != 200) {
        show('warning', 'Preencha todos os campos');
      }
    });
};

export default EditarFuncionarioRegistoHoras;
