import Instance from '../../../../utils/Axios';

const EditarAusencia = (ydata, id, ausenciaId, show) => async (dispatch) => {
  await Instance()
    .put(`/recursos/ausencias/${ausenciaId}/${id}`, { ydata })
    .then(({ data }) => {
      dispatch({ type: 'PUT_AUSENCIA', payload: data });
      show('success', 'A ausência do funcionário foi editada');
    })
    .catch((err) => {
      // eslint-disable-next-line eqeqeq
      if (err.response.status != 200) {
        show('warning', 'Preencha todos os campos');
      }
    });
};

export default EditarAusencia;
