import Instance from '../../../../utils/Axios';

const AdicionarFeriasFuncionarios = (ydata, show) => async (dispatch) => {
  await Instance()
    .post('/recursos/ferias/ ', { ydata })
    .then(({ data }) => {
      dispatch({ type: 'POST_FERIAS_FUNCIONARIOS', payload: data });
      show('success', 'Foi criada um novo registo de férias');
    })
    .catch((err) => {
      console.log(err.response);
      if (err.response) {
        if (err.response.status === 500) {
          show('danger', `${err.response.data.message}`);
        }
      } else {
        show('warning', `Não poderam ser adicionadas novas férias`);
      }
    });
};

export default AdicionarFeriasFuncionarios;
