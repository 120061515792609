/* eslint-disable react/jsx-no-duplicate-props */
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import './modalAdicionar.css';
// MUI
import { TextField, FormControlLabel, Checkbox, Autocomplete, Box } from '@mui/material';
// Picker
import { DatePicker } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import ptLocale from 'date-fns/locale/pt';
import PropTypes from 'prop-types';
import { format } from 'date-fns';
import { NumberFormatBase } from 'react-number-format';

// Components
import HorasButtonsPeriodo from './HorasButtonsPeriodo';

// ACTIONS
import GetHorasByDateInterval from '../../../../../../../../redux/actions/TemposActions/Escritorio/Adicionar/GetHorasByDateInterval';

const NumberFormatCustom = React.forwardRef(function NumberFormatCustom(props, ref) {
  const { onChange, ...other } = props;

  return (
    <NumberFormatBase
      {...other}
      getInputRef={ref}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        });
      }}
      // thousandSeparator
      isNumericString
      suffix="h"
    />
  );
});
NumberFormatCustom.propTypes = {
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};

function RegistoDiario({
  novoRegisto,
  setNovoRegisto,
  classes,
  setHoras,
  valueTipoAtrasoAutoComplete,
  setValueTipoAtrasoAutoComplete,
  valueTipoPeriodoAutoComplete,
  setValueTipoPeriodoAutoComplete,
  tiposperiodos,
  tiposatrasos,
  validationNovoRegistoPeriodo,
  errorsNovoRegistoPeriodo,
  validateOnChange = true,
  useWindowDimensions,
}) {
  const { width } = useWindowDimensions();
  const dispatch = useDispatch();

  // ---------------------------- Definição da hora -----------------------//

  // useEffect(
  //   () =>
  //     setNovoRegisto({
  //       ...novoRegisto,
  //       // qtdPeriodo: value && value.horasMensais,
  //     }),
  //   [],
  // );

  const formatedDataInicioPeriodo =
    novoRegisto && format(novoRegisto.dataInicioPeriodo, 'yyyy-MM-dd');
  const formatedDataFim = novoRegisto && format(novoRegisto.dataFim, 'yyyy-MM-dd');

  const qtdPeriodo = useSelector((state) => state.tempos.totalHoras);
  const novaQtdPeriodo = () => {
    dispatch(
      GetHorasByDateInterval(
        formatedDataInicioPeriodo,
        formatedDataFim,
        setNovoRegisto,
        novoRegisto,
      ),
    );
  };

  useEffect(() => {
    novaQtdPeriodo();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [novoRegisto.dataInicioPeriodo, novoRegisto.dataFim]);

  useEffect(() => {
    setNovoRegisto({
      ...novoRegisto,
      qtdPeriodo: qtdPeriodo && qtdPeriodo.total,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [qtdPeriodo]);
  // --------------------------------------------------------------------//

  const handleDataInicioChange = (date) => {
    setNovoRegisto({
      ...novoRegisto,
      dataInicioPeriodo: date,
    });
    // novaQtdPeriodo();

    if (validateOnChange)
      validationNovoRegistoPeriodo({ dataInicioPeriodo: date, dataFim: novoRegisto.dataFim });
  };
  const handleDataFimChange = (date) => {
    setNovoRegisto({
      ...novoRegisto,
      dataFim: date,
    });
    // novaQtdPeriodo();

    if (validateOnChange)
      validationNovoRegistoPeriodo({
        dataInicioPeriodo: novoRegisto.dataInicioPeriodo,
        dataFim: date,
      });
  };

  const handleData = (currentHoras) => {
    // eslint-disable-next-line no-param-reassign
    novoRegisto.qtd = currentHoras;
    setHoras(currentHoras);
    if (novoRegisto.tipo === 1) {
      setNovoRegisto({
        ...novoRegisto,
        qtdDiario: currentHoras,
      });
    }
    if (novoRegisto.tipo === 2) {
      setNovoRegisto({
        ...novoRegisto,
        qtdPeriodo: currentHoras,
      });
    }
  };

  const handleChangeHoras = (e) => {
    setNovoRegisto({
      ...novoRegisto,
      qtdPeriodo: parseInt(e.target.value, 10),
    });
  };

  // ------------------------------ Handle Falta--------------------------//

  const handleFaltas = (falta) => {
    setNovoRegisto({ ...novoRegisto, faltou: falta });
  };

  // --------------------------------------------------------------------//

  // ------------------------ Handle Autocompletes----------------------//
  const handleChangeAtrasos = (value) => {
    setValueTipoAtrasoAutoComplete(value);
    setNovoRegisto({
      ...novoRegisto,
      tipoAtrasoId: value.id,
    });
  };

  const handleChangePeriodo = (value) => {
    setValueTipoPeriodoAutoComplete(value);
    setNovoRegisto({
      ...novoRegisto,
      tipoPeriodoId: value.id,
    });
  };
  // --------------------------------------------------------------------//

  const handleChange = (e) => {
    console.log(e.target.name, e.target.value);
    setNovoRegisto({
      ...novoRegisto,
      [e.target.name]: e.target.value,
    });
  };

  return (
    <>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 2 }}>
        <Box sx={{ width: '45%' }}>
          <LocalizationProvider dateAdapter={AdapterDateFns} locale={ptLocale}>
            <b>Data Inicio</b>
            <DatePicker
              style={{ width: '96%' }}
              margin="normal"
              id="date-picker-dialog"
              // label="Data Inicio"
              format="dd/MM/yyyy"
              okLabel="ok"
              cancelLabel="cancelar"
              value={novoRegisto && novoRegisto.dataInicioPeriodo}
              onChange={handleDataInicioChange}
              renderInput={(params) => (
                <TextField
                  {...params}
                  inputProps={{
                    ...params.inputProps,
                    placeholder: 'dd/mm/aaaa',
                  }}
                  InputProps={{
                    ...params.InputProps,
                    disableUnderline: true,
                  }}
                  variant="outlined"
                  size="small"
                />
              )}
              {...(errorsNovoRegistoPeriodo.dataInicioPeriodo && {
                error: true,
                helperText: errorsNovoRegistoPeriodo.dataInicioPeriodo,
              })}
            />
          </LocalizationProvider>
        </Box>
        <Box sx={{ width: '45%' }}>
          <LocalizationProvider dateAdapter={AdapterDateFns} locale={ptLocale}>
            <b>Data Fim</b>
            <DatePicker
              style={
                width > 800
                  ? { width: '100%', marginLeft: '14px' }
                  : { width: '100%', marginLeft: '8px' }
              }
              margin="normal"
              id="date-picker-dialog"
              // label="Data Fim"
              format="dd/MM/yyyy"
              okLabel="ok"
              cancelLabel="cancelar"
              value={novoRegisto && novoRegisto.dataFim}
              onChange={handleDataFimChange}
              renderInput={(params) => (
                <TextField
                  {...params}
                  inputProps={{
                    ...params.inputProps,
                    placeholder: 'dd/mm/aaaa',
                  }}
                  InputProps={{
                    ...params.InputProps,
                    disableUnderline: true,
                  }}
                  variant="outlined"
                  size="small"
                />
              )}
              // helperText="Data inválida"
              {...(errorsNovoRegistoPeriodo.dataFim && {
                error: true,
                helperText: errorsNovoRegistoPeriodo.dataFim,
              })}
            />
          </LocalizationProvider>
        </Box>
      </Box>
      <Box sx={{ display: 'flex' }}>
        <Box sx={{ width: '20%' }}>
          <FormControlLabel
            control={
              <Checkbox
                checked={novoRegisto.faltou}
                onChange={() => {
                  handleFaltas(!novoRegisto.faltou);
                }}
                name="falta"
                disabled={
                  !(
                    (novoRegisto && Object.keys(novoRegisto.funcionarioId).length > 0) ||
                    novoRegisto.funcionarioId != 0
                  )
                }
              />
            }
            label="Falta"
          />
        </Box>
        <Box sx={{ display: 'flex', justifyContent: 'flex-end', width: '80%' }}>
          <div className="horasBorderPeriodoTextfield">
            {/* <h4>{novoRegisto.qtdPeriodo}h</h4> */}
            <TextField
              className={classes.horasBorderPeriodo}
              style={{ width: '100%', height: '40px' }}
              size="small"
              value={novoRegisto.qtdPeriodo}
              onChange={handleChangeHoras}
              name="qtdPeriodo"
              id="qtdPeriodo"
              inputProps={{
                style: { textAlign: 'center' },
              }}
              // eslint-disable-next-line react/jsx-no-duplicate-props
              InputProps={{
                inputComponent: NumberFormatCustom,
              }}
              variant="outlined"
            />
          </div>
          <div>
            <HorasButtonsPeriodo recurso={novoRegisto} classes={classes} handleData={handleData} />
          </div>
        </Box>
      </Box>
      <div>
        <div className="autocompletes">
          {novoRegisto && novoRegisto.faltou == true ? (
            <div className="tipoPeriodo">
              <b>Tipo de Periodo</b>
              <Autocomplete
                id="tipoPeriodoId"
                name="tipoPeriodoId"
                noOptionsText="Sem dados"
                onChange={(e, value) => handleChangePeriodo(value)}
                clearText="Limpar"
                openText="Abrir"
                closeText="Fechar"
                value={valueTipoPeriodoAutoComplete}
                options={tiposperiodos || [{ id: 0, descricao: '' }]}
                getOptionLabel={(option) => (option.descricao ? `${option.descricao} ` : '')}
                filterOptions={(options, state) => {
                  return options.filter(
                    (option) =>
                      option.id > 0 &&
                      `${option.descricao}`.toLowerCase().includes(state.inputValue.toLowerCase()),
                  );
                }}
                getOptionSelected={(option, value) => {
                  // if (!value.id) return true;

                  return value.id === option.id;
                }}
                autoComplete
                includeInputInList
                renderInput={(params) => (
                  <TextField
                    {...params}
                    // label="Tipo de Período"
                    placeholder="Tipo de Período"
                    variant="outlined"
                    size="small"
                  />
                )}
              />
            </div>
          ) : null}

          <div
            className={
              novoRegisto.faltou == true ? 'periodo-atrasos-true' : 'periodo-atrasos-false'
            }
          >
            <b>Tipo de Atraso</b>
            <Autocomplete
              id="tipoAtrasoId"
              name="tipoAtrasoId"
              noOptionsText="Sem dados"
              onChange={(e, value) => handleChangeAtrasos(value)}
              clearText="Limpar"
              openText="Abrir"
              closeText="Fechar"
              value={valueTipoAtrasoAutoComplete}
              options={tiposatrasos || [{ id: 0, descricao: '' }]}
              getOptionLabel={(option) => (option.descricao ? `${option.descricao} ` : '')}
              filterOptions={(options, state) => {
                return options.filter(
                  (option) =>
                    option.id > 0 &&
                    `${option.descricao}`.toLowerCase().includes(state.inputValue.toLowerCase()),
                );
              }}
              getOptionSelected={(option, value) => {
                // if (!value.id) return true;

                return value.id === option.id;
              }}
              autoComplete
              includeInputInList
              renderInput={(params) => (
                <TextField
                  {...params}
                  // label="Tipo de Atrasos"
                  placeholder="Tipo de Atraso"
                  variant="outlined"
                  size="small"
                />
              )}
            />
          </div>
        </div>
      </div>
      <Box style={{ marginTop: '10px' }}>
        <Box>
          <b>Observações</b>
          <TextField
            // label="Observações"
            placeholder="Observações"
            name="observacoes"
            variant="outlined"
            style={{ width: '100%' }}
            size="small"
            value={novoRegisto.observacoes || ''}
            onChange={handleChange}
            multiline
          />
        </Box>
      </Box>
    </>
  );
}

export default RegistoDiario;
