import Instance from '../../../../utils/Axios';

const GetDiasDisponiveis = (recursoId, yearChosen) => async (dispatch) => {
  if (recursoId) {
    const stateToken = localStorage.token;
    await Instance()
      .get(`/syncdiasferias/all_dias_ferias_disponiveis_recurso/${recursoId}/${yearChosen}`)
      .then(({ data }) => {
        if (stateToken && dispatch) {
          dispatch({
            type: 'GET_DIAS_DISPONIVEIS',
            payload: data.yData.all_dias_ferias_por_marcar_recurso,
          });
        }
      })
      .catch(({ err }) => console.log(err));
  }
};

export default GetDiasDisponiveis;
