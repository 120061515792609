import Instance from '../../../utils/Axios';

const TodasAsObras =
  (estadosProjetoPreSelectedId, estadosSubProjetoPreSelectedId) => async (dispatch) => {
    const stateToken = localStorage.token;
    const estadosProjetosId = estadosProjetoPreSelectedId;
    const estadosSubProjetosId = estadosSubProjetoPreSelectedId;

    if (stateToken) {
      await Instance()
        .get('/obras', {
          params: {
            estadosProjetosId,
            estadosSubProjetosId,
          },
        })
        .then(({ data }) => {
          dispatch({ type: 'GET_TODAS_OBRAS', payload: data.yData });
        })
        .catch((err) => console.log(err));
    }
  };

export default TodasAsObras;
