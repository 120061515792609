/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable object-shorthand */
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { Box, Button } from '@mui/material';
import MUIDataTable from 'mui-datatables';
import { format } from 'date-fns';
// Icons

import { FiCheckSquare, FiEdit, FiTrash2, FiXSquare } from 'react-icons/fi';

// Components

function TabelaRegistos({ handleOpen, searchText }) {
  const [responsive] = useState('standard');
  const [tableBodyHeight] = useState('100%');
  const [tableBodyMaxHeight] = useState('');
  const consultarRegistoEscritorio = useSelector(
    (state) => state.tempos.consultarRegistoEscritorio,
  );
  const columns = [
    {
      name: 'id',
      label: 'id',
      options: {
        filter: true,
        sort: false,
        empty: true,
        display: false,
        // display: false,
        setCellProps: () => ({
          // align: 'right',
        }),
      },
    },
    {
      name: 'recursos_nome2',
      label: 'Nome',
      options: {
        filter: true,
        sort: true,
        empty: true,
        // display: false,
        setCellProps: () => ({
          // align: 'right',
        }),
      },
    },
    {
      name: 'data',
      label: 'Data',
      options: {
        filter: true,
        sort: true,
        empty: true,

        customBodyRender: (value, tableMeta) => {
          const formatedCheckIn = format(new Date(tableMeta.rowData[2]), 'dd-MM-yyyy');

          return (
            <div
              className="table-btns-categorias"
              style={{ display: 'flex', justifyContent: 'flex-start' }}
            >
              <p>{formatedCheckIn}</p>
            </div>
          );
        },
      },
    },
    {
      name: 'qtd',
      label: 'Horas',
      options: {
        filter: true,
        sort: true,
        empty: true,
        // display: false,
        setCellProps: () => ({
          // align: 'right',
        }),
      },
    },
    {
      name: 'faltou',
      label: 'Presença',
      options: {
        filter: true,
        // sort: false,
        empty: true,

        // eslint-disable-next-line no-unused-vars
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <div
              className="table-btns-categorias"
              style={{ display: 'flex', justifyContent: 'flex-start', width: '100px' }}
            >
              {tableMeta.rowData[4] == 0 ? (
                <FiCheckSquare strokeWidth={1} size={30} />
              ) : (
                <FiXSquare strokeWidth={1} size={30} />
              )}
            </div>
          );
        },
      },
    },
    {
      name: 'id',
      label: ' ',
      options: {
        filter: true,
        sort: false,
        empty: true,
        setCellProps: () => ({
          align: 'center',
        }),
        customBodyRender: (value, tableMeta) => {
          return (
            <div className="table-btns-tiposatrasos">
              <Button
                onClick={(e) =>
                  // window.alert(`Clicked "Edit" for row ${tableMeta.rowIndex}`)
                  handleOpen('edit', e, value, tableMeta)
                }
              >
                <FiEdit strokeWidth={1} size={30} color="#000" />
              </Button>
              <Button
                onClick={(e) => {
                  handleOpen('delete', e, value, tableMeta);
                }}
              >
                <FiTrash2 strokeWidth={1} size={30} color="#000" />
              </Button>
            </div>
          );
        },
      },
    },
  ];

  const options = {
    filter: false,
    search: false,
    searchText: searchText,
    filterType: 'dropdown',
    print: false,
    download: false,
    viewColumns: false,
    selectableRows: 'none',
    responsive,
    tableBodyHeight,
    tableBodyMaxHeight,
    elevation: 0,

    // customToolbar: () => {
    //   return <CustomToolbar handleOpen={handleOpen} />;
    // },

    // eslint-disable-next-line no-unused-vars
    // customToolbarSelect: (selectedRows, displayData, setSelectedRows) => (
    //   <div style={{ padding: '24px' }} />
    // ),
    textLabels: {
      body: {
        noMatch: 'Sem Registos encontrados',
        columnHeaderTooltip: (column) => `${column.label}`,
      },
      toolbar: {
        search: 'Pesquisar',
      },
      pagination: {
        next: 'Proxima Página',
        previous: 'Página Anterior',
        rowsPerPage: 'Mostrar:',
        displayRows: 'de',
      },
      selectedRows: {
        text: 'linha(s) selecionada(s)',
      },
    },
  };
  return (
    <>
      <Box sx={{ borderBottom: '1px solid #000', mb: 5 }}>
        <h4
          style={{
            width: '100%',
          }}
          className="text-underline-title"
        >
          Registos
        </h4>
      </Box>
      <MUIDataTable
        description="Nada para mostrar"
        data={consultarRegistoEscritorio}
        columns={columns}
        options={options}
      />
    </>
  );
}

export default TabelaRegistos;
