import Instance from '../../../utils/Axios';

const GetFuncionario = (id, navigate) => async (dispatch) => {
  const stateToken = localStorage.token;
  const currentYear = new Date().getFullYear();
  if (stateToken) {
    Instance()
      .get(`/syncdiasferias/auto/${id}`)
      .then(() => {
        Instance()
          .get(`/syncdiasferias/all_dias_ferias_disponiveis_recurso/${id}/${currentYear}/`)
          .then(({ data }) => {
            if (stateToken && dispatch) {
              dispatch({
                type: 'GET_DIAS_DISPONIVEIS',
                payload: data.yData.all_dias_ferias_por_marcar_recurso,
              });
            }
          })
          .catch(({ err }) => console.log(err))
          .then(() => {
            Instance()
              .get(`/syncdiasferias/all_dias_ferias_marcados_recurso/${id}/`)
              .then(({ data }) => {
                if (stateToken && dispatch) {
                  dispatch({
                    type: 'GET_DIAS_MARCADOS',
                    payload: data.yData.all_dias_ferias_marcados_recurso,
                  });
                }
              })
              .catch(({ err }) => console.log(err));
          })
          .then(() => {
            Instance()
              .get(`/syncdiasferias/all_dias_ferias_marcados_empresa/${id}/`)
              .then(({ data }) => {
                if (stateToken && dispatch) {
                  dispatch({
                    type: 'GET_DIAS_MARCADOS_EMPRESA',
                    payload: data.yData.all_dias_ferias_marcados_empresa,
                  });
                }
              })
              .catch(({ err }) => console.log(err));
          })
          .then(() => {
            Instance()
              .get(`recursos/funcionariosbyid/${id}`)
              .then(({ data }) => {
                // console.log(data);
                if (stateToken) {
                  if (data && data.yData.length == 0) {
                    navigate('/app/administracao/funcionarios');
                  } else {
                    dispatch({
                      type: 'GET_FUNCIONARIO',
                      payload: data.yData[0],
                    });
                  }
                }
              })
              .catch(({ err }) => console.log(err));
          });
      })
      .catch(({ err }) => console.log(err));
  }
};

export default GetFuncionario;
//     // await Instance()
//     //   .get(`recursos/funcionariosbyid/${id}`)
//     //   .then(({ data }) => {
//     //     console.log(data);
//     //     if (stateToken) {
//     //       if (data && data.yData.length == 0) {
//     //         navigate('/app/administracao/funcionarios');
//     //       } else {
//     //         dispatch({
//     //           type: 'GET_FUNCIONARIO',
//     //           payload: data.yData[0],
//     //         });
//     //       }
//     //     }
//     //   })
//     //   .catch(({ err }) => console.log(err));
//   }
// };

// export default GetFuncionario;
