/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable react/jsx-no-duplicate-props */
/* eslint-disable import/order */
/* eslint-disable no-shadow */
/* eslint-disable no-unused-expressions */
/* eslint-disable react/prop-types */
/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable no-use-before-define */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { Box, Button, CircularProgress, TextField } from '@mui/material';
// Redux
import { useDispatch, useSelector } from 'react-redux';
// Actions
// location
import { useLocation, useNavigate } from 'react-router';
// Icons
import Notification from 'rc-notification';
// Lista de Recursos
// DATE PICKER
import { format, isValid, differenceInDays } from 'date-fns';

import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

import ptLocale from 'date-fns/locale/pt';
import MapRecursos from './Recursos/mapRecursos';
import { FullWideNotification } from '../../../../../../shared/components/Notification';
import RecursosAssociados from '../../../../../../redux/actions/TemposActions/RecursosAssociadosAction';
import RegistarTempos from '../../../../../../redux/actions/TemposActions/RegistarTemposAction';

import InfoGraficos from '../../../../../../redux/actions/Dashboard/infoGraficosAction';
import ModalAdicionarRecurso from './AdicionarRecurso/AdicionarFuncionarioModal';
import { Col } from 'reactstrap';

// ---------------------------- Window dimensions -------------------------//

function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height,
  };
}

export function useWindowDimensions() {
  const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return windowDimensions;
}
// ---------------------------------------------------------------------//

let notificationTC = null;

function TemposForm({ theme, fetchHistorico, fetchFuncNaoAss, classes }) {
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const idObra = location.pathname.split('/')[4];

  const { width } = useWindowDimensions();
  const [selectedDate, setSelectedDate] = React.useState(new Date());
  const [openAdd, setOpenAdd] = useState();
  const [recurso, setRecurso] = useState({
    recursosId: '',
  });

  const [loading, setLoading] = useState(false);
  const [loadingGuardar, setLoadingGuardar] = useState(false);

  const userRole = useSelector((state) => state.user.role);

  // CAMPOS DO FORM

  const da = new Date();
  if (
    da.getDay() - 2 == 0 ||
    da.getDay() - 2 == 6 ||
    da.getDay() - 3 == 0 ||
    da.getDay() - 3 == 6
  ) {
    da.setDate(da.getDate() - 5);
  } else {
    da.setDate(da.getDate() - 3);
  }
  const limitDate = format(da, 'yyyy-MM-dd');

  let dtMinDate = new Date(limitDate);

  const dtMaxDate = new Date();

  if (userRole === 2 || userRole === 3) {
    dtMinDate = 0;
  }

  const fetchTempos = () => {
    const id = location.pathname.split('/')[4];

    if (isValid(selectedDate)) {
      const fdate = format(selectedDate, 'yyyy-MM-dd');

      const maxDate = differenceInDays(new Date(dtMaxDate), new Date(selectedDate));
      const minDate = differenceInDays(new Date(selectedDate), new Date(dtMinDate));

      if (userRole == 2 && maxDate >= 0) {
        dispatch(RecursosAssociados(id, fdate));
      } else if (minDate >= 0 && maxDate >= 0) {
        dispatch(RecursosAssociados(id, fdate));
      }
    } else {
      console.log('Invalid Date');
    }
  };

  const recursosAssociados = useSelector((state) => state.tempos.recursosAssociados);
  const oldRecursosAssociados = useSelector((state) => state.tempos.oldRecursosAssociados);
  // const novoFuncionario = useSelector((state) => state.tempos.adicionarUtilizador);
  // const funcionarioExcluido = useSelector((state) => state.tempos.utilizadorExcluido);
  const tiposfaltas = useSelector((state) => state.tiposFaltas.dropdownTiposFaltas);
  const tiposperiodos = useSelector((state) => state.tiposPeriodos.dropdownTiposPeriodos);
  const tiposatrasos = useSelector((state) => state.tiposAtrasos.dropdownTiposAtrasos);

  // Antes de desaparecer as horas quando se adicionava um funcionario
  // useEffect(() => fetchTempos(), [selectedDate, novoFuncionario, funcionarioExcluido]);

  useEffect(() => {
    fetchTempos();
  }, [selectedDate]);

  // ---------- Notification Sytem ------------------- //

  useEffect(() => {
    Notification.newInstance({ style: { top: 65, zIndex: 9999 } }, (n) => {
      notificationTC = n;
    });
  }, [dispatch]);

  const showNotification = ({ notification, position }, direction) => {
    const notificationDefaultProps = {
      content: notification(theme),
      duration: 2,
      closable: true,
      className: `${position} ${direction}-support`,
    };

    notificationTC.notice({
      ...notificationDefaultProps,
      style: { top: -60, left: 0 },
    });
  };

  const show = (color, mensagem) =>
    showNotification({
      notification() {
        return <FullWideNotification color={color} message={mensagem} theme={theme} />;
      },
      position: 'full',
    });

  // ---------------------------------------------------- //

  // ------------------- Modal ------------------------ //
  const handleOpen = (btn, e) => {
    e.preventDefault();
    setLoading(false);
    if (btn == 'add') {
      setOpenAdd(true);
      // toggle();
    }
  };

  const handleClose = (btn, e) => {
    e.preventDefault();

    if (btn == 'add') {
      setOpenAdd(false);
    }
  };
  // ------------------------------------------------- //

  const handleDateChange = (date) => {
    setSelectedDate(date);
    // fetchTempos();
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const arrayComp = oldRecursosAssociados === JSON.stringify(recursosAssociados);
    const finaldate = format(selectedDate, 'yyyy-MM-dd');

    if (arrayComp == true) {
      show('warning', 'Estas horas já foram registadas');
    } else {
      // console.log(guardarHoras);

      setLoadingGuardar(true);

      const yData = recursosAssociados.map((x) => ({
        obraId: x.obras_id,
        recursoId: x.recursos_id,
        qtd: x.qtd,
        data: finaldate,
        faltou: x.faltou,
        tipoPeriodosId: x.tipoperiodos_id,
        tipoAtrasosId: x.tipoatrasos_id,
        observacoes: x.observacoes,
      }));
      dispatch(RegistarTempos(yData, navigate, show))
        .then(() => fetchHistorico())
        .then(() => fetchTempos())
        .then(() => setLoadingGuardar(false));

      dispatch(InfoGraficos());
    }
  };

  const recursosAssociadosOld = { ...recursosAssociados };

  return (
    <>
      <Box style={{ display: 'flex' }}>
        <Col md={12} sm={12}>
          <Box
            sx={{
              mb: 3,
              display: 'flex',
              flexDirection: width < 820 ? 'column' : 'row',
              alignItems: width < 820 ? 'center' : null,
              justifyContent: width < 820 ? 'center' : null,
            }}
          >
            <Box sx={{ display: 'flex', flexDirection: 'column' }}>
              <LocalizationProvider dateAdapter={AdapterDateFns} locale={ptLocale}>
                <b>Escolha uma data</b>
                <DatePicker
                  id="date-picker-dialog"
                  format="dd/MM/yyyy"
                  okLabel="ok"
                  cancelLabel="cancelar"
                  value={selectedDate}
                  onChange={handleDateChange}
                  KeyboardButtonProps={{
                    'aria-label': 'change date',
                  }}
                  invalidDateMessage="Data Inválida"
                  maxDateMessage="Não pode exceder o atual dia"
                  minDateMessage="Apenas pode ver 3 dias atrás"
                  minDate={dtMinDate}
                  maxDate={dtMaxDate}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      inputProps={{
                        ...params.inputProps,
                        placeholder: 'dd/mm/aaaa',
                      }}
                      InputProps={{
                        ...params.InputProps,
                        disableUnderline: true,
                      }}
                      variant="outlined"
                      size="small"
                    />
                  )}
                />
              </LocalizationProvider>
            </Box>
            {/* <AdicionarRecursoModal idObra={idObra} width={width} /> */}
            <ModalAdicionarRecurso
              openAdd={openAdd}
              handleClose={handleClose}
              recurso={recurso}
              setRecurso={setRecurso}
              show={show}
              idObra={idObra}
              fetchFuncNaoAss={fetchFuncNaoAss}
              recursosAssociados={recursosAssociados}
              recursosAssociadosOld={recursosAssociadosOld}
              // Loading
              loading={loading}
              setLoading={setLoading}
            />
            <Button
              onClick={(e) => {
                handleOpen('add', e);
              }}
              className={classes.btnGuardar}
              variant="contained"
              sx={{
                float: width > 820 ? 'right' : null,
                alignItems: 'center',
                mt: width <= 820 ? '10px' : 2.5,
                ml: width > 820 ? 'auto' : null,
                mr: 0,
              }}
              size="small"
            >
              Adicionar Funcionário
            </Button>
          </Box>
        </Col>
      </Box>
      <div>
        {recursosAssociados == null ? (
          fetchTempos()
        ) : (
          <MapRecursos
            recursos={recursosAssociados}
            handleSubmit={handleSubmit}
            idObra={idObra}
            tiposfaltas={tiposfaltas}
            tiposperiodos={tiposperiodos}
            tiposatrasos={tiposatrasos}
            useWindowDimensions={useWindowDimensions}
            fetchFuncNaoAss={fetchFuncNaoAss}
          />
        )}

        <br />

        <Button
          onClick={(e) => {
            handleSubmit(e);
          }}
          className={loadingGuardar ? classes.btnGuardarLoading : classes.btnGuardar}
          size="small"
          variant="contained"
          style={{ float: 'right' }}
          disabled={!!loadingGuardar}
        >
          {/* {!loading && <IoMdSave style={{ color: 'white', marginRight: '10px' }} />} */}
          {loadingGuardar && (
            <CircularProgress size={14} style={{ color: '#9d9d9d', marginRight: '10px' }} />
          )}
          Guardar
        </Button>
      </div>
    </>
  );
}

TemposForm.propTypes = {};

export default TemposForm;
