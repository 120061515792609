import Instance from '../../../utils/Axios';

const RecuperarSenha = (ydata, show) => async (dispatch) => {
  const stateToken = localStorage.token;

  if (stateToken) {
    await Instance()
      .post('/users/recuperarsenha', { ydata })
      .then(({ data }) => {
        console.log(data);
        if (stateToken) {
          dispatch({ type: 'RECUPERAR_SENHA', payload: data });
          show('success', 'Senha redefinida');
        }
      })
      .catch(({ err }) => console.log(err));
  }
};

export default RecuperarSenha;
