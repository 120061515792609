import Instance from '../../../utils/Axios';

const FuncionariosUtilizadores = (userId) => async (dispatch) => {
  const stateToken = localStorage.token;
  if (stateToken) {
    await Instance()
      .get(`/recursos/funcionariosUtilizador/${userId}`)
      .then(({ data }) => {
        if (stateToken) {
          dispatch({ type: 'GET_FUNCIONARIOS_UTILIZADORES', payload: data.yData });
        }
      })
      .catch(({ err }) => console.log(err));
  }
};

export default FuncionariosUtilizadores;
