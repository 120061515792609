/* eslint-disable default-param-last */
import { recursosInitialState } from '../InitialState/recursos';

const recursosReducer = (state = recursosInitialState, { type, payload }) => {
  switch (type) {
    case 'GET_OBRAS_SEM_RECURSOS':
      return {
        ...state,
        obrasSemRecursos: payload,
      };
    case 'GET_RECURSOS':
      return {
        ...state,
        infoObra: payload,
      };
    case 'GET_ENCARREGADOS':
      return {
        ...state,
        encarregados: payload,
      };
    case 'GET_FUNCIONARIOS':
      return {
        ...state,
        funcionarios: payload,
      };
    case 'POST_RECURSOS':
      return {
        ...state,
        associarRecursos: payload,
      };
    case 'GET_CORES_OBRAS_CRIAR_EQUIPAS':
      return {
        ...state,
        cores: payload,
      };
    default:
      return state;
  }
};

export default recursosReducer;
