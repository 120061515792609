import Instance from '../../../../utils/Axios';

const ConsultarRegistoEscritorio =
  (dataInicio, dataFim, obraId, funcionario) => async (dispatch) => {
    await Instance()
      .get(`/obras/registohorasescritorio/horas`, {
        params: { dataInicio, dataFim, obraId, funcionario },
      })
      .then(({ data }) => {
        console.log(data);
        dispatch({ type: 'GET_CONSULTAR_REGISTO_ESCRITORIO', payload: data.yData });
      })
      .catch((err) => {
        console.log(err);
      });
  };

export default ConsultarRegistoEscritorio;
