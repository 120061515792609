/* eslint-disable default-param-last */
import { relatoriosInitialState } from '../InitialState/relatorios';

const relatoriosReducer = (state = relatoriosInitialState, { type, payload }) => {
  switch (type) {
    case 'GET_TODAS_OBRAS_RELATORIO':
      return {
        ...state,
        todasAsObrasRelatorio: payload,
      };
    case 'GET_TODOS_FUNCIONARIOS_RELATORIO':
      return {
        ...state,
        todosOsFuncionariosRelatorio: payload,
      };

    // RELATORIOS
    case 'GET_RELATORIO_MENSAL_HORAS':
      return {
        ...state,
        relatorioMensalHoras: payload,
      };

    case 'GET_RELATORIO_COLABORADORES_ATIVOS':
      return {
        ...state,
        relatorioColaboradoresAtivos: payload,
      };

    case 'GET_RELATORIO_VISTA_GERAL_DE_PROJETO':
      return {
        ...state,
        relatorioVistaGeralDeProjeto: payload,
      };

    default:
      return state;
  }
};

export default relatoriosReducer;
