/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable react/prop-types */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { reduxForm, Form } from 'redux-form';
import { connect, useDispatch, useSelector } from 'react-redux';
import EyeIcon from 'mdi-react/EyeIcon';
import KeyVariantIcon from 'mdi-react/KeyVariantIcon';
import AccountOutlineIcon from 'mdi-react/AccountOutlineIcon';
import EmailutlineIcon from 'mdi-react/EmailOutlineIcon';
import PropTypes from 'prop-types';
import { Alert, Button } from 'reactstrap';
import { useNavigate } from 'react-router';
import Notification from 'rc-notification';

import { ThemeProps } from '../../../shared/prop-types/ReducerProps';

// COMPONETS
import { FullWideNotification } from '../../../shared/components/Notification';
import DefinirSenha from '../../../redux/actions/UsersActions/DefinirSenhaAction';

let notificationTC = null;

function DefinirSenhaForm({
  errorMessage,
  errorMsg,
  fieldUser,
  typeFieldUser,
  theme,
  fetch,
  hash,
}) {
  const [showPassword1, setShowPassword1] = useState(false);
  const [showPassword2, setShowPassword2] = useState(false);
  const dados = useSelector((state) => state.users.dadosDefSenha);

  const [user, setUser] = useState({
    nome: dados.nome,
    email: dados.username,
    password: '',
    confirmarPassword: '',
  });

  // Check store state

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const showPasswordToggle1 = () => {
    setShowPassword1(!showPassword1);
  };
  const showPasswordToggle2 = () => {
    setShowPassword2(!showPassword2);
  };

  const handleChange = (e) => {
    e.preventDefault();

    setUser({
      ...user,
      [e.target.name]: e.target.value,
    });
  };

  useEffect(() => {
    Notification.newInstance({ style: { top: 65 } }, (n) => {
      notificationTC = n;
    });
  }, [dispatch]);

  useEffect(() => {
    fetch();
  }, []);

  const showNotification = ({ notification, position }, direction) => {
    const notificationDefaultProps = {
      content: notification(theme),
      duration: 2,
      closable: true,
      className: `${position} ${direction}-support`,
    };

    notificationTC.notice({
      ...notificationDefaultProps,
      style: { top: -60, left: 0 },
    });
  };

  const show = (color, mensagem) =>
    showNotification({
      notification() {
        return <FullWideNotification color={color} message={mensagem} theme={theme} />;
      },
      position: 'full',
    });

  const submitUser = (e) => {
    e.preventDefault();

    const ydata = [
      {
        nome: user.nome,
        email: user.email,
        password: user.password,
        confirmarPassword: user.confirmarPassword,
        setup: hash,
      },
    ];

    dispatch(DefinirSenha(ydata, show));
    navigate('/login');
  };

  return (
    <Form className="form login-form" onSubmit={submitUser}>
      <Alert color="danger" isOpen={!!errorMessage || !!errorMsg}>
        {errorMessage}
        {errorMsg}
      </Alert>
      {/* <ColorStatesFullWidth /> */}
      {/* ------------------------ Nome ---------------------------- */}
      <div className="form__form-group">
        <span className="form__form-group-label">Nome</span>
        <div className="form__form-group-field">
          <div className="form__form-group-icon">
            <AccountOutlineIcon />
          </div>
          <input
            onChange={handleChange}
            value={user.nome}
            name="username"
            type={typeFieldUser}
            placeholder="Nome"
            className="input-without-border-radius"
            disabled
          />
        </div>
      </div>
      {/* ------------------------ Email ---------------------------- */}
      <div className="form__form-group">
        <span className="form__form-group-label">{fieldUser}</span>
        <div className="form__form-group-field">
          <div className="form__form-group-icon">
            <EmailutlineIcon />
          </div>
          <input
            onChange={handleChange}
            value={user.email}
            name="email"
            type={typeFieldUser}
            placeholder={fieldUser}
            className="input-without-border-radius"
            disabled
          />
        </div>
      </div>
      {/* ------------------------ Senha ---------------------------- */}
      <div className="form__form-group">
        <span className="form__form-group-label">Nova Senha</span>
        <div className="form__form-group-field">
          <div className="form__form-group-icon">
            <KeyVariantIcon />
          </div>
          <input
            onChange={handleChange}
            name="password"
            value={user.password}
            type={showPassword1 ? 'text' : 'password'}
            placeholder="Nova senha"
            className="input-without-border-radius"
            required
          />
          <button
            type="button"
            className={`form__form-group-button${showPassword1 ? ' active' : ''}`}
            onClick={showPasswordToggle1}
          >
            <EyeIcon />
          </button>
        </div>
      </div>
      {/* ---------------------- Confirmar Senha -------------------- */}
      <div className="form__form-group">
        <span className="form__form-group-label">Confirmar Senha</span>
        <div className="form__form-group-field">
          <div className="form__form-group-icon">
            <KeyVariantIcon />
          </div>
          <input
            onChange={handleChange}
            name="confirmarPassword"
            value={user.confirmarPassword}
            type={showPassword2 ? 'text' : 'password'}
            placeholder="Confirmar Senha"
            className="input-without-border-radius"
            required
          />
          <button
            type="button"
            className={`form__form-group-button${showPassword2 ? ' active' : ''}`}
            onClick={showPasswordToggle2}
          >
            <EyeIcon />
          </button>
        </div>
      </div>
      <div className="account__btns">
        <Button className="account__btn btn btn-primary" color="primary" onClick={submitUser}>
          Confirmar Senha
        </Button>
      </div>
    </Form>
  );
}

DefinirSenhaForm.propTypes = {
  errorMessage: PropTypes.string,
  errorMsg: PropTypes.string,
  fieldUser: PropTypes.string,
  typeFieldUser: PropTypes.string,
  theme: ThemeProps.isRequired,
};

DefinirSenhaForm.defaultProps = {
  errorMessage: '',
  errorMsg: '',
  fieldUser: 'Email',
  typeFieldUser: 'text',
};

export default connect((state) => ({
  errorMsg: state.user.error,
  theme: state.theme,
}))(reduxForm()(DefinirSenhaForm));
