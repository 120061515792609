export const obrasInitialState = {
  obras: [],
  infoObra: [],
  historicoMensal: [],
  recursosDefinidos: [],

  // Cores
  cores: [],

  // Obras validadas apenas pelo Utilizador
  validacaoObraAdmin: {},
};
