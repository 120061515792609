import Instance from '../../../utils/Axios';

const CorObra = (id, cor, oldCorObra, show) => async (dispatch) => {
  const stateToken = localStorage.token;

  console.log(cor);

  await Instance()
    .put(`/obras/${id}`, { ydata: [{ cor }] })
    .then(({ data }) => {
      if (cor == oldCorObra) {
        show('warning', 'A cor selecionada é a mesma');
      } else if (cor != oldCorObra && stateToken) {
        dispatch({ type: 'PUT_COR_OBRA', payload: data.yData[0] });
        show('success', 'Cor da obra, definida!');
      }
    })
    .catch(({ err }) => console.log(err));
};

// Meter aqui o put para enviar o HE

export default CorObra;
