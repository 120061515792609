/* eslint-disable react/no-array-index-key */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/prop-types */
import React, { useEffect } from 'react';
import { Card, CardBody } from 'reactstrap';
import { useLocation, useNavigate } from 'react-router';
// import RecursosForm from "./RecursosForm";
import { useDispatch, useSelector } from 'react-redux';
import RegistarHorasCard from './RegistarHorasCard';
import CardInfoObraTempos from './InfoObra/CardInfoObra';

import InfoObraTempo from '../../../../../../redux/actions/TemposActions/InfoObraAction';

function CardTempos({ fetchHistorico, classes, useWindowDimensions }) {
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const id = location.pathname.split('/')[4];
  const fetchInfoObra = () => {
    dispatch(InfoObraTempo(id, navigate));
  };

  const ydata = useSelector((state) => state.tempos.infoObra);

  useEffect(() => {
    fetchInfoObra();
  }, []);

  return (
    <>
      <Card>
        <CardBody>
          <CardInfoObraTempos ydata={ydata} />
        </CardBody>
      </Card>
      <Card>
        <CardBody>
          <RegistarHorasCard
            fetchHistorico={fetchHistorico}
            classes={classes}
            useWindowDimensions={useWindowDimensions}
          />
        </CardBody>
      </Card>

      {/* {historico === undefined ? (
        fetchHistorico()
      ) : (
        <Col md={12} lg={12}>
          <Card>
            <CardBody>
              <div className="react-table__wrapper">
                <div className="card__title">
                  <h5 className="bold-text">Histórico</h5>
                </div>
              </div>
              {historico.map((registos, index) => (
                <Fragment key={index}>
                  <ListaHistorico registos={registos} fetchHistorico={fetchHistorico} />
                </Fragment>
              ))}
            </CardBody>
          </Card>
        </Col>
      )} */}
    </>
  );
}

CardTempos.propTypes = {};

export default CardTempos;
