/* eslint-disable react/prop-types */
import { Box, Collapse, IconButton } from '@mui/material';
import { styled } from '@mui/styles';
import React, { useState, useEffect } from 'react';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const ExpandMore = styled((props) => {
  // eslint-disable-next-line no-unused-vars
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
  marginLeft: 'auto',
  transition: theme.transitions.create('transform', {
    duration: theme.transitions.duration.shortest,
  }),
}));

function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height,
  };
}

export function useWindowDimensions() {
  const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return windowDimensions;
}

function CardInfoObra({ ydata, fetchRecursos }) {
  const { width } = useWindowDimensions();
  const [expanded, setExpanded] = useState(false);
  // const [widthDimension, setWidthDimension] = useState('25%');
  const handleExpandClick = () => {
    setExpanded(!expanded);
  };
  return (
    <div>
      {ydata === undefined ? (
        fetchRecursos()
      ) : (
        <>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <h4 className="text-underline-title-dark">
              {ydata.id} - {ydata.descricao}
            </h4>
            <ExpandMore
              expand={expanded}
              onClick={handleExpandClick}
              aria-expanded={expanded}
              aria-label="show more"
            >
              <ExpandMoreIcon />
            </ExpandMore>
          </Box>
          <Collapse in={expanded} timeout="auto" unmountOnExit>
            <Box
              sx={{
                display: 'flex',
                flexDirection: width < 760 ? 'column' : 'row',
                mt: 3,
              }}
            >
              <Box
                sx={{
                  width: width < 1250 ? '50%' : '25%',
                  mx: 2,
                  mb: width < 760 ? 3 : 0,
                }}
              >
                <Box sx={{ borderBottom: '1px solid #000', mb: 3 }}>
                  <h4
                    style={{
                      width: '100%',
                    }}
                    className="text-underline-title"
                  >
                    Informação da Obra
                  </h4>
                </Box>
                {ydata.obraMorada1 ? (
                  <p>
                    <b>Morada:</b> {ydata.obraMorada1}
                  </p>
                ) : null}
                {ydata.obraCodigoPostal ? (
                  <p>
                    <b>Cód. Postal:</b> {ydata.obraCodigoPostal}
                  </p>
                ) : null}
                {ydata.obraDistrito ? (
                  <p>
                    <b>Distrito:</b> {ydata.obraDistrito}
                  </p>
                ) : null}
              </Box>
              <Box sx={{ width: width < 1250 ? '50%' : '25%', mx: 2 }}>
                <Box sx={{ borderBottom: '1px solid #000', mb: 3 }}>
                  <h4
                    style={{
                      width: '100%',
                    }}
                    className="text-underline-title"
                  >
                    Informação do Cliente
                  </h4>
                </Box>
                {ydata.clienteNome ? (
                  <p>
                    <b>Cliente:</b> {ydata.clienteNome}
                  </p>
                ) : null}
                {ydata.clienteMorada1 || ydata.clienteMorada2 ? (
                  <p>
                    <b>Morada:</b> {ydata.clienteMorada1} {ydata.clienteMorada2}
                  </p>
                ) : null}
                {ydata.clienteCodigoPostal ? (
                  <p>
                    <b>Cód.Postal:</b> {ydata.clienteCodigoPostal}
                  </p>
                ) : null}
                {ydata.clienteCidade ? (
                  <p>
                    <b>Distrito: {ydata.clienteCidade}</b>
                  </p>
                ) : null}
              </Box>
            </Box>
          </Collapse>
        </>
      )}
    </div>
  );
}

export default CardInfoObra;
