export const temposInitialState = {
  obrasComRecursos: [],
  recursosAssociados: [],
  oldRecursosAssociados: {},
  registarTempos: [],
  infoObra: [],
  historicoObras: [],
  adicionarUtilizador: [],
  utilizadorExcluido: [],
  funcionariosNaoAssociados: [],

  // Registo escritorio
  obrasRegistoEscritorio: [],
  funcionariosEscriorio: [],
  consultarRegistoEscritorio: [],
  adicionarRegistoEscritorio: [],
  registoEscritorio: {},
  removerRelatorioEscritorio: {},
  // // Periodo
  totalHoras: {},
};
