/* eslint-disable max-len */
import React, { useState } from 'react';
import { getYear } from 'date-fns';
import ModalAdicionar from './modalAdicionar';
import ModalDelete from './modalDelete';
import ModalEdit from './modalEdit';
import CardFerias from './CardFerias';

function FeriasFuncionarios({
  classes,
  defaultMaterialTheme,
  ferias,
  funcId,
  fetchFuncionarioFerias,
  show,
  // Loading
  loading,
  setLoading,
}) {
  const [formFerias, setFormFerias] = useState({
    ano: '',
    diasFeriasTotal: '',
    diasFeriasManual: '',
  });
  const [openAdicionar, setOpenAdicionar] = useState(false);
  const [openEdit, setOpenEdit] = useState(false);
  const [openDelete, setOpenDelete] = useState(false);

  const [feriasId, setFeriasId] = useState(null);

  // Open Modals (editar e delete)

  const handleOpen = (btn, e, ferId, infoRow) => {
    e.preventDefault();

    setLoading(false);
    if (btn == 'edit') {
      setOpenEdit(true);
      setFeriasId(ferId);
      setFormFerias({
        ...formFerias,
        ano: infoRow.rowData[1],
        diasFeriasTotal: infoRow.rowData[2],
        diasFeriasManual: infoRow.rowData[3],
      });
    } else if (btn == 'delete') {
      setOpenDelete(true);
      setFeriasId(ferId);
    } else {
      setOpenAdicionar(true);
    }
  };

  const handleClose = (btn, e) => {
    e.preventDefault();
    // setErrorsTipoFalta({});

    if (btn == 'edit') {
      setOpenEdit(false);
      setFeriasId(null);
    } else if (btn == 'delete') {
      setOpenDelete(false);
      setFeriasId(null);
    } else {
      setOpenAdicionar(false);

      // setErrorsTipoFalta({});
    }
  };

  // -------------------------- Validacao Ferias --------------------//

  const [errorsFerias, setErrorsFerias] = useState({});

  const validationFerias = (fieldValues = formFerias) => {
    const temp = { ...errorsFerias };

    const year = getYear(new Date());

    if ('ano' in fieldValues) {
      if (fieldValues.ano > year + 5) {
        temp.ano = `Máx. até ${year + 5}`;
      } else if (fieldValues.ano < year - 5) {
        temp.ano = `Min. até ${year - 5}`;
      } else {
        temp.ano = '';
      }
    }
    if ('diasFeriasTotal' in fieldValues) {
      temp.diasFeriasTotal = fieldValues.diasFeriasTotal ? '' : 'Campo Obrigatório';
      if (parseInt(fieldValues.diasFeriasTotal, 10) >= parseInt(formFerias.diasFeriasManual, 10)) {
        temp.diasFeriasManual = '';
      } else if (
        parseInt(fieldValues.diasFeriasTotal, 10) < parseInt(formFerias.diasFeriasManual, 10)
      ) {
        temp.diasFeriasManual = 'Tem de ser menor que o dia Total';
      }
    }

    if ('diasFeriasManual' in fieldValues) {
      if (parseInt(fieldValues.diasFeriasManual, 10) > parseInt(formFerias.diasFeriasTotal, 10)) {
        temp.diasFeriasManual = 'Tem de ser menor que o dia Total';
      } else {
        temp.diasFeriasManual = '';
      }
    }

    setErrorsFerias({
      ...temp,
    });
    if (fieldValues == formFerias) {
      return Object.values(temp).every((x) => x == '');
    }
    return false;
  };

  // -----------------------------------------------------------------------//

  return (
    <>
      <ModalAdicionar
        openAdicionar={openAdicionar}
        handleClose={handleClose}
        defaultMaterialTheme={defaultMaterialTheme}
        formFerias={formFerias}
        setFormFerias={setFormFerias}
        funcId={funcId}
        fetchFuncionarioFerias={fetchFuncionarioFerias}
        show={show}
        validationFerias={validationFerias}
        errorsFerias={errorsFerias}
        // Loading
        loading={loading}
        setLoading={setLoading}
      />
      <ModalDelete
        openDelete={openDelete}
        handleClose={handleClose}
        fetchFuncionarioFerias={fetchFuncionarioFerias}
        funcId={funcId}
        // ausenciaId={ausenciaId}
        show={show}
        feriasId={feriasId}
        // Loading
        loading={loading}
        setLoading={setLoading}
      />
      <ModalEdit
        openEdit={openEdit}
        handleClose={handleClose}
        defaultMaterialTheme={defaultMaterialTheme}
        formFerias={formFerias}
        setFormFerias={setFormFerias}
        feriasId={feriasId}
        funcId={funcId}
        fetchFuncionarioFerias={fetchFuncionarioFerias}
        show={show}
        validationFerias={validationFerias}
        errorsFerias={errorsFerias}
        // Loading
        loading={loading}
        setLoading={setLoading}
      />
      <div>
        <CardFerias
          ferias={ferias}
          classes={classes}
          handleOpen={handleOpen}
          handleClose={handleClose}
        />
      </div>
    </>
  );
}

export default FeriasFuncionarios;
