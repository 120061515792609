import Instance from '../../../utils/Axios';

const AssociarRecursos = (ydata, show) => async (dispatch) => {
  await Instance()
    .post('/obras/associarobras', { ydata })
    .then(({ data }) => {
      dispatch({ type: 'POST_RECURSOS', payload: data });
      show('success', 'Foram associados novos funcionários á obra');
    })
    .catch((err) => {
      console.log(err.response);
      // if (err.response.status != 200) {
      //   show('warning', 'Preencha todos os campos');
      // }

      if (err.response) {
        if (err.response.status === 500) {
          show('danger', `${err.response.data.message}`);
        }
      } else {
        show('warning', `Preencha todos os campos`);
      }
    });
};

export default AssociarRecursos;
