import Instance from '../../../utils/Axios';

const FuncionariosRecursos = () => async (dispatch) => {
  const stateToken = localStorage.token;
  if (stateToken) {
    await Instance()
      .get('/recursos/funcionarios')
      .then(({ data }) => {
        if (stateToken) {
          dispatch({ type: 'GET_FUNCIONARIOS', payload: data.yData });
        }
      })
      .catch(({ err }) => console.log(err));
  }
};

export default FuncionariosRecursos;
