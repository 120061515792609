import Instance from '../../../utils/Axios';

const GetFuncionariosValidacao = () => async (dispatch) => {
  await Instance()
    .get(`/obras/validacao/funcionariosDropdown`)
    .then(({ data }) => {
      dispatch({ type: 'GET_FUNCIONARIOS_VALIDACAO', payload: data.yData });
    })
    .catch((err) => {
      console.log(err);
    });
};

export default GetFuncionariosValidacao;
