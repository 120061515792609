/* eslint-disable max-len */
import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { format } from 'date-fns';

import ModalDelete from './modalDelete';
import ModalEdit from './modalEdit';
import CardHoras from './CardHoras';

// Actions
import GetFuncionarioHoras from '../../../../../../../redux/actions/FuncionariosActions/horas/getFuncionarioHorasAction';

function HorasFuncionarios({
  classes,
  funcId,
  show, // Loading
  loading,
  setLoading,
  width,
}) {
  const dispatch = useDispatch();
  const [formHoras, setFormHoras] = useState({
    registoHoras: '',
    obra: '',
    data: '',
  });

  const [openEdit, setOpenEdit] = useState(false);
  const [openDelete, setOpenDelete] = useState(false);

  const [registoHorasId, setRegistoHorasId] = useState(null);

  // --------------------- Consulta ----------------------//
  const [dataInicioConsulta, setDataInicioConsulta] = useState(new Date());
  const [dataFimConsulta, setDataFimConsulta] = useState(new Date());

  const handleDataInicioConsultaChange = (date) => {
    setDataInicioConsulta(date);
  };

  const handleDataFimConsultaChange = (date) => {
    setDataFimConsulta(date);
  };

  // console.log(dataInicioConsulta, dataFimConsulta);

  // ----------------------------------------------------------//

  const fetchHorasFuncionario = () => {
    const dataInicioFormated = format(dataInicioConsulta, 'yyyy-MM-dd');
    const dataFimFormated = format(dataFimConsulta, 'yyyy-MM-dd');

    dispatch(GetFuncionarioHoras(funcId, dataInicioFormated, dataFimFormated));
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    fetchHorasFuncionario();
  }, []);

  const horas = useSelector((state) => state.funcionarios.horas);

  // Open Modals (editar e delete)

  const handleOpen = (btn, e, regHorasId, infoRow) => {
    e.preventDefault();

    console.log(infoRow);
    setLoading(false);
    if (btn == 'edit') {
      setOpenEdit(true);
      setRegistoHorasId(regHorasId);
      setFormHoras({
        ...formHoras,
        registoHoras: infoRow.rowData[4],
        obra: infoRow.rowData[1],
        data: infoRow.rowData[3],
      });

      console.log(formHoras);
    } else if (btn == 'delete') {
      setOpenDelete(true);
      setRegistoHorasId(regHorasId);
    }
  };

  const handleClose = (btn, e) => {
    e.preventDefault();
    // setErrorsTipoFalta({});

    if (btn == 'edit') {
      setOpenEdit(false);
      setRegistoHorasId(null);
    } else if (btn == 'delete') {
      setOpenDelete(false);
      setRegistoHorasId(null);
    }
  };

  return (
    <>
      <ModalEdit
        openEdit={openEdit}
        handleClose={handleClose}
        show={show}
        formHoras={formHoras}
        setFormHoras={setFormHoras}
        registoHorasId={registoHorasId}
        fetchHorasFuncionario={fetchHorasFuncionario}
        // Loading
        loading={loading}
        setLoading={setLoading}
      />
      <ModalDelete
        openDelete={openDelete}
        handleClose={handleClose}
        fetchHorasFuncionario={fetchHorasFuncionario}
        funcId={funcId}
        // ausenciaId={ausenciaId}
        show={show}
        registoHorasId={registoHorasId}
        // Loading
        loading={loading}
        setLoading={setLoading}
      />
      {/* <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <h4>
            <strong>Férias</strong>
          </h4>
        </div> */}

      <CardHoras
        horas={horas}
        classes={classes}
        dataInicioConsulta={dataInicioConsulta}
        dataFimConsulta={dataFimConsulta}
        handleDataInicioConsultaChange={handleDataInicioConsultaChange}
        handleDataFimConsultaChange={handleDataFimConsultaChange}
        handleOpen={handleOpen}
        handleClose={handleClose}
        show={show}
        funcId={funcId}
        width={width}
      />
    </>
  );
}

export default HorasFuncionarios;
