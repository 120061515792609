/* eslint-disable react/no-unstable-nested-components */
import React, { useState, useEffect } from 'react';
import { reduxForm, Form } from 'redux-form';
import { connect, useDispatch } from 'react-redux';
// eslint-disable-next-line import/no-unresolved
import { useNavigate } from 'react-router';
import PropTypes from 'prop-types';
import Notification from 'rc-notification';
import { Button, IconButton, InputAdornment, TextField } from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import '../../../containers/Account/LogIn/login.css';
import styled from '@emotion/styled';

// ICONS
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';

import { ThemeProps } from '../../prop-types/ReducerProps';

// COMPONETS
import { FullWideNotification } from '../Notification';
import InfoGraficos from '../../../redux/actions/Dashboard/infoGraficosAction';
import FooterLinks from '../../../containers/Account/Footer';

// REDUX

// ACTION
import { SignIn } from '../../../redux/actions/authActions';

// PATH

// Checking what´s in store

const CssTextField = styled(TextField)({
  // borderRadius: '15px',
  '& label.Mui-focused': {
    color: '#464255 !important',
  },
  '& .MuiInput-underline:after': {
    borderBottomColor: '#464255 !important',
  },
  '& .MuiOutlinedInput-root': {
    // borderRadius: '15px',
    transition: '0.2s',
    background: 'white',

    '& input': {
      backgroundColor: 'white',
      transition: '0.2s',
      borderRadius: '5px',
    },
    '& fieldset': {
      color: '#474a52 !important',
      transition: '0.2s',
    },

    '&:hover fieldset': {
      borderColor: '#464255 !important',
    },
    '&.Mui-focused fieldset': {
      borderColor: '#464255 !important',
    },
  },
});

const defaultMaterialTheme = createTheme({
  palette: {
    primary: { main: '#E21450' },
  },
});

let notificationTC = null;

function LogInForm({ fieldUser, typeFieldUser, theme, classes, dimensions }) {
  const [showPassword, setShowPassword] = useState(false);
  const [user, setUser] = useState({
    username: '',
    password: '',
  });

  // Check store state

  const dispatch = useDispatch();

  const showPasswordToggle = () => {
    setShowPassword(!showPassword);
  };

  const handleChange = (e) => {
    e.preventDefault();

    setUser({
      ...user,
      [e.target.name]: e.target.value,
    });
  };

  const navigate = useNavigate();

  useEffect(() => {
    dispatch(InfoGraficos());
    Notification.newInstance({ style: { top: 65 } }, (n) => {
      notificationTC = n;
    });
  }, [dispatch]);

  const showNotification = ({ notification, position }, direction) => {
    const notificationDefaultProps = {
      content: notification(theme),
      duration: 2,
      closable: true,
      className: `${position} ${direction}-support`,
    };

    notificationTC.notice({
      ...notificationDefaultProps,
      style: { top: -60, left: 0 },
    });
  };

  const show = (color, mensagem) =>
    showNotification({
      notification() {
        return <FullWideNotification color={color} message={mensagem} theme={theme} />;
      },
      position: 'full',
    });

  const submitUser = (e) => {
    e.preventDefault();

    const ydata = [
      {
        user: {
          username: user.username,
          password: user.password,
        },
      },
    ];
    dispatch(InfoGraficos());
    dispatch(SignIn(ydata, navigate, show));
    dispatch({
      type: 'DADOS_FORMULARIO_VALIDACAO',
      payload: { dataInicio: '', dataFim: '' },
    });
  };

  return (
    <ThemeProvider theme={defaultMaterialTheme}>
      {/* <Alert severity="error" isOpen={!!errorMessage || !!errorMsg}>
        {errorMessage}
        {errorMsg}
      </Alert> */}
      {/* <ColorStatesFullWidth /> */}
      <div className="home-right">
        {' '}
        <div
          className="login-form"
          style={{ marginTop: dimensions && dimensions.width < 800 ? '25vh' : '' }}
        >
          <div className="titulo">
            <h3> </h3>
            <p>PLATAFORMA DE {process.env.REACT_APP_TITLE} </p>
          </div>

          <Form onSubmit={submitUser} className="loginForm">
            <p className="subtitulo">Insira a sua conta e password</p>
            <CssTextField
              variant="outlined"
              className="emailInput"
              size="small"
              onChange={handleChange}
              value={user.username}
              name="username"
              type={typeFieldUser}
              label={fieldUser}
            />

            <CssTextField
              variant="outlined"
              size="small"
              onChange={handleChange}
              name="password"
              value={user.password}
              type={showPassword ? 'text' : 'password'}
              label="Palavra Passe"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={showPasswordToggle}
                      onMouseDown={handleChange}
                      edge="end"
                    >
                      {showPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
            <div className="login-submit-section">
              <Button
                variant="contained"
                type="submit"
                color="primary"
                onClick={submitUser}
                style={{ width: '100px' }}
              >
                Entrar
              </Button>
            </div>
          </Form>
        </div>
        <div className="bottom">
          <div style={{ padding: '20px 40px' }}>
            <FooterLinks />
          </div>
        </div>
      </div>
    </ThemeProvider>
  );
}

LogInForm.propTypes = {
  // errorMessage: PropTypes.string,
  // errorMsg: PropTypes.string,
  fieldUser: PropTypes.string,
  typeFieldUser: PropTypes.string,
  theme: ThemeProps.isRequired,
};

LogInForm.defaultProps = {
  // errorMessage: '',
  // errorMsg: '',
  fieldUser: 'Utilizador',
  typeFieldUser: 'text',
};

export default connect((state) => ({
  errorMsg: state.user.error,
  theme: state.theme,
}))(reduxForm()(LogInForm));
