import Instance from '../../../../../utils/Axios';

const GetTodasAsObrasValAD = () => async (dispatch) => {
  await Instance()
    .get(`/obras/validacao/obrasAdminDropdown`)
    .then(({ data }) => {
      dispatch({ type: 'GET_OBRAS_VALIDACAO_AD', payload: data.yData });
    })
    .catch((err) => {
      console.log(err);
    });
};

export default GetTodasAsObrasValAD;
