import Instance from '../../../utils/Axios';

const EditarUser =
  (
    id,
    ydata,
    oldUser,
    show,
    handleClose,
    e,
    //  toggle
  ) =>
  async (dispatch) => {
    // const arrayEquality = JSON.stringify(oldUser) === JSON.stringify(ydata);
    const arrayEquality = JSON.stringify(oldUser[0]) === JSON.stringify(ydata[0]);

    console.log(oldUser[0], ydata[0], arrayEquality);

    await Instance()
      .put(`/users/${id}`, { ydata })
      .then(({ data }) => {
        dispatch({ type: 'PUT_USER', payload: data.yData[0] });
        if (arrayEquality === true) {
          show('warning', 'Os campos preenchidos são iguais');
        } else {
          show('success', 'Utiizador editado');
          if (handleClose && e) {
            handleClose('edit', e);
          }
        }
      })
      .catch((err) => {
        if (err.response.status != 200) {
          show('warning', 'Verifique todos os campos');
        }
      });
  };

export default EditarUser;
