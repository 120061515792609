/* eslint-disable react/jsx-no-duplicate-props */
/* eslint-disable no-param-reassign */
/* eslint-disable no-shadow */
/* eslint-disable react/button-has-type */
/* eslint-disable react/prop-types */
/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import { useSelector } from 'react-redux';
import { format, addYears, getDaysInMonth, startOfMonth, endOfMonth } from 'date-fns';
import { Box, TextField } from '@mui/material';
// import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import { DesktopDatePicker } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';

import ptLocale from 'date-fns/locale/pt';

// PDF
import { Container, Row } from 'reactstrap';
import PDF from './pdf';
import HistoricoCompleto from './Tabelas/historicoCompleto';
import HistoricoPeriodo from './Tabelas/historicoPeriodo';

// const { ExcelFile } = ReactExport;
// const { ExcelSheet } = ReactExport.ExcelFile;

function TabelaHistorico({
  fdate,
  obra,
  classes,
  // DATEPICKER

  handleDateChange,
}) {
  const user = useSelector((state) => state.user);
  const ydata = useSelector((state) => state.obras.historicoMensal);

  const valorHora = user && user.permissoes && user.permissoes.includes('MOSTRAR_VALOR_HORA');

  const mes = fdate.getMonth() + 1;
  const ano = fdate.getFullYear();
  const data = format(new Date(), 'dd-MM-yyyy');
  const dataInicio = format(startOfMonth(new Date(fdate)), 'yyyy-MM-dd');
  const dataFim = format(endOfMonth(new Date(fdate)), 'yyyy-MM-dd');

  const handlePdf = (horas) => (e) => {
    e.preventDefault();
    const diasMes = getDaysInMonth(new Date(dataInicio));

    PDF(ano, mes, data, horas, obra, diasMes, valorHora);
  };

  // const maxDate = () => {
  //   const now = new Date();
  //   if (getMonth(new Date()) + 1 == 12) {
  //     return new Date(now.getFullYear() + 1, 0, 31);
  //   }

  //   return new Date();
  // };

  // useEffect(() => {
  //   if (getDate(new Date()) > 25) {
  //     setFdate(addMonths(fdate, 1));
  //   }
  // }, []);

  return (
    <>
      <Container>
        <Row>
          <Box sx={{ display: 'flex', flexDirection: 'column' }}>
            <LocalizationProvider dateAdapter={AdapterDateFns} locale={ptLocale}>
              <b>Escolha o mês</b>
              {user && user.role == 2 ? (
                <DesktopDatePicker
                  maxDate={addYears(new Date(), 1)}
                  margin="normal"
                  views={['month', 'year']}
                  okLabel="ok"
                  cancelLabel="cancelar"
                  //   helperText="With min and max"
                  value={fdate}
                  onChange={handleDateChange}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      inputProps={{
                        ...params.inputProps,
                        placeholder: 'mm aaa',
                      }}
                      InputProps={{
                        ...params.InputProps,
                        disableUnderline: true,
                      }}
                      variant="outlined"
                      size="small"
                    />
                  )}
                />
              ) : (
                <DesktopDatePicker
                  maxDate={addYears(new Date(), 1)}
                  margin="normal"
                  views={['month', 'year']}
                  okLabel="ok"
                  cancelLabel="cancelar"
                  //   helperText="With min and max"
                  value={fdate}
                  onChange={handleDateChange}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      inputProps={{
                        ...params.inputProps,
                        placeholder: 'mm aaa',
                      }}
                      InputProps={{
                        ...params.InputProps,
                        disableUnderline: true,
                      }}
                      variant="outlined"
                      size="small"
                    />
                  )}
                  disabled
                />
              )}
            </LocalizationProvider>
          </Box>
          {/* <Col sm={12} md={6}>
            <div
              style={{
                textAlign: 'right',
                display: 'flex',
                justifyContent: 'flex-end',
                alignItems: 'center',
                height: '100%',
              }}
            >
              {user && user.role == 2 ? (
                <>
                  <Button
                    size="small"
                    // color="primary"
                    onClick={handlePdf(ydata)}
                    style={{ marginTop: '2px' }}
                    className={classes.btnLightGreen}
                  >
                    Gerar PDF
                  </Button>
                </>
              ) : null}
            </div>{' '}
          </Col> */}
        </Row>
      </Container>
      {/* <ExcelFile
        filename="TesteExcel"
        element={<Button color="primary">Export Data</Button>}
      >
        <ExcelSheet dataSet={DataSet} name="Teste de Excel"></ExcelSheet>
      </ExcelFile> */}

      {user && user.role == 2 ? (
        <HistoricoCompleto
          fdate={fdate}
          handlePdf={handlePdf}
          valorHora={valorHora}
          mes={mes}
          ano={ano}
          horas={ydata}
          dataInicio={dataInicio}
          dataFim={dataFim}
          classes={classes}
        />
      ) : (
        <HistoricoPeriodo
          fdate={fdate}
          handlePdf={handlePdf}
          valorHora={valorHora}
          mes={mes}
          ano={ano}
        />
      )}

      {/* <MUIDataTable
        title="Historico"
        description="Nada para mostrar"
        data={ydata}
        columns={columns}
        options={options}
      /> */}
    </>
  );
}

export default TabelaHistorico;
