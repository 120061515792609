export const funcionariosInitialState = {
  todosFuncionarios: [],
  funcionarioEditado: [],
  categorias: [],
  funcionario: [],

  // HORAS
  horas: [],
  registoHoras: {},
  registoHorasEditadas: {},
  registoHoasRemovidas: {},

  // AUSENCIAS
  ausencias: [],
  ausenciaRemovida: {},
  ausencia: {},
  ausenciaEditada: {},

  // OBSERVACOES
  observacoes: [],
  observacaoRemovida: {},
  observacaoEditada: {},

  // FERIAS
  ferias: [],
  feriasRemovidas: {},
  feriasEditadas: {},
};
